import { useDownloadUploadModel } from "@/hooks/useDownloadUploadModel";
import { router } from "@/main";
import { Route } from "@/paths";
import { Provider } from "jotai";
import { Outlet, matchPath } from "react-router-dom";
import { DeferredRender } from "../DeferedRender/DeferedRender";
import { LayoutWorkstation } from "../LayoutWorkstation/LayoutWorkStation";
import { AdminWarning } from "./AdminWarning";
import { AnonSaveTools } from "./AnonSaveTools";
import { Loader } from "./Loader";
import { NotAvailableOnMobile } from "./NotAvailableOnMobile";
import { Sidebar } from "./Sidebar/Sidebar";
import { WelcomeVideo } from "./WelcomeVideo";

export const PageModel = () => {
  const pathParams = matchPath(
    { path: Route.ModelTab, end: false },
    location.pathname,
  )?.params;

  if (!pathParams) router.navigate("historicals", { replace: true });

  return (
    <Provider>
      {window.location.hash && <DownloadUploadModel />}
      <LayoutWorkstation>
        <LayoutWorkstation.Sidebar visibleFrom="md">
          <Sidebar />
        </LayoutWorkstation.Sidebar>

        <DeferredRender>
          <Outlet />
        </DeferredRender>
      </LayoutWorkstation>
      <Loader />
      <WelcomeVideo />
      <DeferredRender>
        <AdminWarning />
        <NotAvailableOnMobile />
        <AnonSaveTools />
      </DeferredRender>
    </Provider>
  );
};

const DownloadUploadModel = () => {
  useDownloadUploadModel();

  return null;
};
