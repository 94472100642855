import {
  equityReturnsAllInvestorsAtom,
  equityReturnsAllInvestorsTotalsAtom,
} from "@/financeModels/owasco/equity";
import { monospaceFontFamily } from "@/theme";
import { formatDollar } from "@/utils/format";
import { Table } from "@mantine/core";
import { Atom, useAtomValue } from "jotai";
import { Fragment } from "react";

export const InvestorDistributionsTable = () => {
  const returns = useAtomValue(equityReturnsAllInvestorsAtom);

  return (
    <>
      <InvestorTable returns={returns} />
    </>
  );
};

type TableValue = typeof equityReturnsAllInvestorsAtom extends Atom<infer T>
  ? T
  : never;

const InvestorTable = ({ returns }: { returns: TableValue }) => {
  const totals = useAtomValue(equityReturnsAllInvestorsTotalsAtom);

  const cols = [
    { title: "Period", field: "period" },
    { title: "Year", field: "year" },
    { title: "Preferred\nDividends", field: "preferredDividends" },
    { title: "Principal\nRepayments", field: "principalRepayments" },
    { title: "Profit\nDistributions", field: "profitDistributions" },
    { title: "Tax\nDistributions", field: "taxDistributions" },
    { title: "Sale\nProceeds", field: "proceedsFromCompanySale" },
    {
      title: "Total Investor\nDistributions",
      field: "totalEquityInvestorDistributions",
    },
  ] as const;

  const items = [
    ...returns,
    {
      ...totals,
      period: "Total",
      year: "",
    },
  ];

  return (
    <Table striped withColumnBorders flex={1}>
      <Table.Thead
        style={{
          position: "sticky",
          top: "0",
          background: "white",
        }}
      >
        <Table.Tr>
          {cols.map((col) => (
            <Table.Th
              ta={"center"}
              key={col.field}
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {col.title}
            </Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody ff={monospaceFontFamily}>
        {items.map((item, i) => (
          <Table.Tr
            key={item.period}
            bg={i === items.length - 1 ? "infomatic.0" : ""}
          >
            {cols.map((col) => (
              <Fragment key={col.field}>
                {["period", "year"].includes(col.field) ? (
                  <Table.Td
                    key={col.field}
                    ta={col.field === "period" ? "center" : "right"}
                  >
                    {col.field === "year" ? item.year : item.period}
                  </Table.Td>
                ) : (
                  <Table.Td key={col.field} ta={"right"}>
                    {item[col.field] === 0
                      ? "$0.00"
                      : formatDollar(item[col.field] as number)}
                  </Table.Td>
                )}
              </Fragment>
            ))}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};
