import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import {
  metricsCommonEquityAtom,
  metricsExternalInvestorsPreferredEquityAtom,
  metricsPreferredEquityAtom,
  metricsSearcherEquityAtom,
  metricsTotalEquityAtom,
} from "@/financeModels/owasco/equity";
import { monospaceFontFamily, textFontFamily } from "@/theme";
import { formatDecimalPercent, formatDollar } from "@/utils/format";
import { toNumberOrNull } from "@/utils/math";
import { ActionIcon, Group, Table } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { RxTriangleDown, RxTriangleRight } from "react-icons/rx";

export const ReturnMetricsTable = () => {
  const [showSubItems, setShowSubItems] = useState(false);
  const metricsPreferredEquity = useAtomValue(metricsPreferredEquityAtom);
  const metricsCommonEquity = useAtomValue(metricsCommonEquityAtom);
  const metricsSearcherEquity = useAtomValue(metricsSearcherEquityAtom);
  const metricsExternalInvestorsPreferredEquity = useAtomValue(
    metricsExternalInvestorsPreferredEquityAtom,
  );
  const metricsTotalEquity = useAtomValue(metricsTotalEquityAtom);

  const investors = [
    {
      key: "searcher",
      title: (
        <Group align="center" wrap="nowrap">
          Searcher (Preferred & Common Equity)
          <ActionIcon
            variant="light"
            color="accent"
            onClick={() => setShowSubItems((v) => !v)}
            size={"sm"}
          >
            {!showSubItems ? (
              <RxTriangleRight size={16} />
            ) : (
              <RxTriangleDown size={16} />
            )}
          </ActionIcon>
        </Group>
      ),
      ...metricsSearcherEquity,
    },

    ...(!showSubItems
      ? []
      : [
          {
            key: "preferredEquity",
            title: (
              <>
                <MdSubdirectoryArrowRight style={{ margin: "0 10px" }} />
                Preferred Equity
              </>
            ),
            ...metricsPreferredEquity,
          },
          {
            key: "commonEquity",
            title: (
              <>
                <MdSubdirectoryArrowRight style={{ margin: "0 10px" }} />
                Common Equity
              </>
            ),
            ...metricsCommonEquity,
          },
        ]),

    {
      key: "externalInvestorsPreferredEquity",
      title: "External Investors (Preferred Equity)",
      ...metricsExternalInvestorsPreferredEquity,
    },

    {
      key: "totalEquity",
      title: "Total Equity",
      ...metricsTotalEquity,
    },
  ] as const;

  return (
    <>
      <LayoutWorkstation.Panel p={0} flex={1} miw={1000}>
        <Table
          striped
          withColumnBorders
          withTableBorder
          style={{ whiteSpace: "nowrap" }}
          w={"100%"}
        >
          <Table.Thead h={60}>
            <Table.Tr>
              <Table.Th>Equity Investors</Table.Th>
              <Table.Th ta={"center"}>
                Company
                <br />
                Ownership
              </Table.Th>
              <Table.Th ta={"center"}>
                Total
                <br />
                Distributions
              </Table.Th>
              <Table.Th ta={"center"}>
                Sales
                <br />
                Proceeds
              </Table.Th>
              <Table.Th ta={"center"}>
                Total
                <br />
                Return
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody ff={monospaceFontFamily}>
            {investors.map((investor) => (
              <Table.Tr
                key={investor.key}
                ta={"right"}
                bg={investor.title === "Total Equity" ? "infomatic.0" : ""}
              >
                <Table.Td ta={"left"} ff={textFontFamily}>
                  {investor.title}
                </Table.Td>
                <Table.Td>
                  {formatDecimalPercent(investor.companyOwnership)}
                </Table.Td>
                <Table.Td>{formatDollar(investor.totalDistributions)}</Table.Td>
                <Table.Td>{formatDollar(investor.saleProceeds)}</Table.Td>
                <Table.Td>{formatDollar(investor.totalReturn)}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </LayoutWorkstation.Panel>

      <LayoutWorkstation.Panel p={0}>
        <Table
          striped
          withColumnBorders
          withTableBorder
          style={{ whiteSpace: "nowrap" }}
        >
          <Table.Thead h={60}>
            <Table.Tr>
              <Table.Th ta={"center"}>IRR</Table.Th>
              <Table.Th ta={"center"}>MOIC</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody ff={monospaceFontFamily}>
            {investors.slice(0, -1).map((investor) => (
              <Table.Tr
                key={investor.key}
                ta={"right"}
                bg={investor.title === "Total Equity" ? "infomatic.0" : ""}
              >
                <Table.Td>{formatDecimalPercent(investor.irr)}</Table.Td>
                <Table.Td>
                  {(toNumberOrNull(investor.moic) || 0).toFixed(2)}x
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </LayoutWorkstation.Panel>
    </>
  );
};
