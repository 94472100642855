import { memo, ReactNode, useDeferredValue, useEffect, useState } from "react";

interface DeferredRenderProps {
  children: ReactNode;
  idleTimeout?: number;
}

export const DeferredRender = memo(({ children }: DeferredRenderProps) => {
  const [render, setRender] = useState<boolean>(false);
  const deferedRender = useDeferredValue(render);

  useEffect(() => {
    setRender(true);
  }, []);

  if (!deferedRender) return null;
  return <>{children}</>;
});
