import { dealDocumentAtom } from "@/atoms/deal";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { Badge, Group, NumberInput } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useDealTextInput } from "./useDealTextInput";

const numberInputDefaultProps = {
  thousandSeparator: ",",
  prefix: "$",
  allowNegative: false,
  decimalScale: 2,
  hideControls: true,
};

export const ListedFinancials = () => {
  const [deal] = useAtomValue(dealDocumentAtom);
  const purchasePrice = useDealTextInput("purchasePrice");
  const cashFlow = useDealTextInput("cashFlow");
  const revenue = useDealTextInput("revenue");

  const purchaseMultiple =
    (deal?.purchasePrice || 0) / (deal?.cashFlow || 0.000001);

  return (
    <LayoutWorkstation.Panel
      label={
        <Group justify="space-between">
          Listed Financials
          {purchaseMultiple > 0.5 && purchaseMultiple < 1000 && (
            <Badge tt={"capitalize"} color="gray" size="md">
              Purchase Multiple: <strong>{purchaseMultiple.toFixed(2)}x</strong>
            </Badge>
          )}
        </Group>
      }
    >
      <NumberInput
        label="Purchase Price"
        {...numberInputDefaultProps}
        {...purchasePrice}
      />
      <NumberInput
        label="Cash Flow / SDE"
        {...numberInputDefaultProps}
        {...cashFlow}
      />

      <NumberInput
        label="Gross Revenue"
        {...numberInputDefaultProps}
        {...revenue}
      />
    </LayoutWorkstation.Panel>
  );
};
