import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { TextInput } from "@mantine/core";
import { useDealTextInput } from "./useDealTextInput";

export const Brokerage = () => {
  const listingLink = useDealTextInput("listingLink");
  const brokerage = useDealTextInput("brokerage");
  const broker = useDealTextInput("broker");

  return (
    <LayoutWorkstation.Panel label="Brokerage">
      <TextInput {...listingLink} label="Deal Listing Link" placeholder="" />
      <TextInput {...brokerage} label="Brokerage Firm" placeholder="" />
      <TextInput {...broker} label="Broker Name" placeholder="" />
    </LayoutWorkstation.Panel>
  );
};
