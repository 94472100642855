import { Route } from "@/paths";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { ActionIcon } from "@mantine/core";
import { useMemo } from "react";
import { HiExternalLink } from "react-icons/hi";
import { Link, generatePath } from "react-router-dom";

export const NameRenderer = (props: CustomCellRendererProps) => {
  const value = useMemo(() => props.value, [props.value]);
  const dealId = useMemo(() => props.node.data.id, [props.node.data.id]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "calc(100% - 25px)",
        }}
      >
        {value || "(unnamed deal)"}
      </span>

      <ActionIcon
        variant="transparent"
        component={Link}
        to={generatePath(Route.Deal, {
          dealId,
        })}
        target="_blank"
        ml={"xs"}
        color="gray"
      >
        <HiExternalLink />
      </ActionIcon>
    </div>
  );
};
