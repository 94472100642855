import { allCategoriesAtom } from "@/atoms/dealTracker/category";
import { DealCategoryBadge } from "@/components/DealCategoryBadge/DealCategoryBadge";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
//import { defaultCategories as categories } from "../categories";

export const CategoryRender = (props: CustomCellRendererProps) => {
  const categories = useAtomValue(allCategoriesAtom);
  const category = useMemo(
    () => categories.find((c) => c.id === props.value),
    [categories, props.value],
  );
  return <DealCategoryBadge category={category!} />;
};
