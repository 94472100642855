import { useAuthContext } from "@/contexts/AuthProvider";
import { update, useCreateDeal, useDeals } from "@/hooks/useDeals";
import { useModel } from "@/hooks/useModel";
import { useCloneModel } from "@/hooks/useModels";
import { Route } from "@/paths";
import { Button, Group, Popover, Select, Stack, Text } from "@mantine/core";
import { arrayUnion } from "firebase/firestore";
import { useState } from "react";
import { HiSave } from "react-icons/hi";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { css } from "vite-plugin-inline-css-modules";

const styles = css`
  @keyframes jump-shaking {
    0%,
    88% {
      transform: translateX(0);
    }
    90% {
      transform: translateY(-9px);
    }
    92% {
      transform: translateY(-9px) rotate(7deg);
    }
    94% {
      transform: translateY(-9px) rotate(-7deg);
    }
    96% {
      transform: translateY(-9px) rotate(7deg);
    }
    98% {
      transform: translateY(-9px) rotate(-7deg);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }

  .shake {
    animation: jump-shaking 4s infinite;
  }
`;

const buttonProps = {
  color: "brand.5",
  pos: "fixed" as const,
  right: 24,
  bottom: 24,
  size: "xl",
  className: styles.shake,
  leftSection: <HiSave size={24} />,
};

export const AnonSaveTools = () => {
  const { user } = useAuthContext();
  const [model] = useModel();

  if (model?.creatorId !== "anonymous") {
    return null;
  }

  if (!user) {
    return (
      <Button
        {...buttonProps}
        component={Link}
        to={generatePath(Route.Signup)}
        state={{ cloneModelId: model?.id }}
      >
        Save Model
      </Button>
    );
  }

  return <LoggedInSaveTools />;
};

const LoggedInSaveTools = () => {
  const navigate = useNavigate();
  const [model] = useModel();
  const [cloneModel] = useCloneModel();
  const [createDeal] = useCreateDeal();
  const [isOpen, setIsOpen] = useState(false);
  const [deals] = useDeals();
  const [exsistingDealId, setExsistingDealId] = useState<string | null>(null);

  //remove if no company name, sort by createdAt
  const filteredDeals = deals
    .filter((deal) => deal.companyName)
    .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
    .map((deal) => ({
      value: deal.id,
      label: deal.companyName,
    }));

  const onNewDeal = async () => {
    if (!model) return;

    const clonedModel = await cloneModel(model?.id, {
      name: "Homepage Model",
    });

    const deal = await createDeal({
      modelIds: [clonedModel!.id],
      companyName: "Homepage Deal " + new Date().toLocaleDateString(),
    });

    navigate(generatePath(Route.Deal, { dealId: deal!.id }));
  };

  const onExsistingDeal = async () => {
    if (!model) return;
    if (!exsistingDealId) return;

    const clonedModel = await cloneModel(model?.id, {
      name: "Homepage Model",
    });

    update(exsistingDealId, {
      // @ts-expect-error ArrayUnion is not a valid type
      modelIds: arrayUnion(clonedModel!.id),
    });

    navigate(generatePath(Route.Deal, { dealId: exsistingDealId }));
  };

  return (
    <Popover
      position="left-end"
      width={448}
      trapFocus
      shadow="md"
      withArrow
      arrowSize={12}
      arrowPosition="center"
      onChange={(opened) => setIsOpen(opened)}
      radius={0}
    >
      <Popover.Target>
        <Button
          {...buttonProps}
          className={!isOpen ? buttonProps.className : ""}
        >
          Save Model
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Group>
          <Stack>
            <Button onClick={onNewDeal}>Create A New Deal</Button>
          </Stack>
          <Text fw={"bold"}>or</Text>
          <Stack>
            <Select
              w={200}
              searchable
              allowDeselect={false}
              limit={4}
              label="Enter company name:"
              placeholder="Acme Corp"
              data={filteredDeals}
              withCheckIcon={false}
              value={exsistingDealId}
              onChange={(value) => setExsistingDealId(value)}
              comboboxProps={{ withinPortal: false }}
            />
            <Button
              disabled={!exsistingDealId}
              w={200}
              onClick={onExsistingDeal}
            >
              Add To Existing Deal
            </Button>
          </Stack>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};
