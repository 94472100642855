import { LayoutPage } from "@/components/LayoutPage/LayoutPage";
import { useAuthContext } from "@/contexts/AuthProvider";
import { auth } from "@/firebase";
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { updateProfile } from "firebase/auth";
import { useState } from "react";
import { useSignOut } from "react-firebase-hooks/auth";

export const PageProfile = () => {
  const { user } = useAuthContext();
  const [signout] = useSignOut(auth);
  const [displayName, setDisplayName] = useState(user?.displayName || "");

  const [loading, setLoading] = useState(false);

  const handleUpdateProfile = async () => {
    if (!user) return;
    try {
      setLoading(true);
      await updateProfile(user, {
        displayName,
      });
      setLoading(false);
      //alert("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      //alert("Failed to update profile");
    }
  };

  return (
    <LayoutPage bg="styledBackgrounds.6">
      <Container my={"xl"}>
        <Text fw="600" fz={40} size={"xs"} mb={"sm"}>
          Profile
        </Text>

        <Grid>
          <Grid.Col span={8}>
            <Card withBorder>
              <Stack>
                <TextInput
                  size="lg"
                  label="Name"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.currentTarget.value)}
                />
                <TextInput
                  size="lg"
                  label="Email"
                  value={user?.email || ""}
                  disabled
                />
                <Button
                  loading={loading}
                  size="lg"
                  w={200}
                  onClick={handleUpdateProfile}
                >
                  Update Profile
                </Button>
              </Stack>
            </Card>
          </Grid.Col>
          <Grid.Col span={4}>
            <Stack>
              <Stack gap={4}>
                <Text size="xs">
                  User Created: {user?.metadata.creationTime}
                </Text>
                <Text size="xs">
                  Last Login: {user?.metadata.lastSignInTime}
                </Text>
              </Stack>
              <Button variant="outline" onClick={signout}>
                Log out
              </Button>
            </Stack>
          </Grid.Col>
        </Grid>
      </Container>
    </LayoutPage>
  );
};
