import { LayoutFullscreen } from "@/components/LayoutFullscreen/LayoutFullscreen";
import { auth, prettyPrintFirebaseErrorMessage } from "@/firebase";
import { useRedirectWhenUserLoads } from "@/hooks/useRedirectIfUserOnPageLoad";
import { Route } from "@/paths";
import {
  Anchor,
  Button,
  Container,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { Link, useLocation } from "react-router-dom";

export const PageLogin = () => {
  const { state } = useLocation();
  const message = state?.message as string | undefined;

  useRedirectWhenUserLoads(Route.DealTracker);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  const [signInWithEmailAndPassword, , loading, error] =
    useSignInWithEmailAndPassword(auth);

  const handleSubmit = async () => {
    await signInWithEmailAndPassword(form.values.email, form.values.password);
  };

  return (
    <LayoutFullscreen>
      <Container size={420} my={40} pb={"xl"}>
        <Text fz={40}>Welcome back!</Text>
        <Text c="dimmed" size="sm" mt={5}>
          Do not have an account yet?{" "}
          <Anchor size="sm" component={Link} to={Route.Signup}>
            Create account
          </Anchor>
        </Text>

        {message && (
          <Text c="green" mt="xl" ta="center">
            {message}
          </Text>
        )}

        <Paper withBorder shadow="md" p={30} mt={30}>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack gap={0}>
              <TextInput
                size="md"
                label="Email"
                placeholder="hello@example.com"
                required
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
              />
              <PasswordInput
                size="md"
                label="Password"
                placeholder="Your password"
                required
                mt="md"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
              />

              <Button
                size="md"
                fullWidth
                mt="xl"
                loading={loading}
                type="submit"
              >
                Sign in
              </Button>

              {error && (
                <Text c="red" mt="xl" ta="center">
                  {prettyPrintFirebaseErrorMessage(error.message)}
                </Text>
              )}
            </Stack>
          </form>
        </Paper>
        <Group p="sm" justify="center">
          <Anchor size="md" component={Link} to={Route.ForgotPassword}>
            Forgot password?
          </Anchor>
        </Group>
      </Container>
    </LayoutFullscreen>
  );
};
