import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import {
  CellTypes,
  DollarCell as DollarCellType,
  MultipleCell as MultipleCellType,
  PercentCell as PercentCellType,
  TextCell as TextCellType,
} from "@/financeModels/owasco/types";
import { useInViewport } from "@mantine/hooks";
import {
  Atom,
  PrimitiveAtom,
  WritableAtom,
  useAtomValue,
  useSetAtom,
} from "jotai";
import parseMoney from "parse-money";
import { memo } from "react";
import { DollarCell } from "./DollarCell";
import { GrowthCell } from "./GrowthCell";
import { MultipleCell } from "./MultipleCell";
import { PercentCell } from "./PercentCell";
import { TextCell } from "./TextCell";
import { defaultHeight, defaultWidth } from "./cell/Wrapper";
import { useTabCoordinates } from "./hooks/useTabCoordinates";

type CellSlotProps = {
  atom: PrimitiveAtom<CellTypes> | Atom<CellTypes>;
  tabX: number;
  tabY: number;
};

const DefaultTabCoords = (p: CellSlotProps) => {
  const setValue = useSetAtom(
    p.atom as WritableAtom<CellTypes, [CellTypes], void>,
  );

  useTabCoordinates({
    ...p,
    onPaste: (value: string) => {
      const money = parseMoney(value);
      if (money == null) return;
      try {
        // @ts-expect-error - setValue can be anything
        setValue((c) => ({ ...c, value: money.amount }));
      } catch (e) {
        console.error(e);
      }
    },
  });

  return null;
};

export const CellSlot = memo(({ atom, ...props }: CellSlotProps) => {
  const cell = useAtomValue(atom);
  const { ref, inViewport } = useInViewport();

  if (!inViewport)
    return (
      <div
        ref={ref}
        style={{
          minWidth: defaultWidth,
          minHeight: defaultHeight,
          position: "relative",
          background: "transparent",
        }}
      >
        <DefaultTabCoords atom={atom} {...props} />
      </div>
    );

  return (
    <div
      ref={ref}
      style={{
        minWidth: defaultWidth,
        minHeight: defaultHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DeferredRender>
        {cell.type === "dollar" && (
          <DollarCell atom={atom as PrimitiveAtom<DollarCellType>} {...props} />
        )}
        {cell.type === "text" && (
          <TextCell atom={atom as PrimitiveAtom<TextCellType>} {...props} />
        )}
        {cell.type === "percent" && (
          <PercentCell
            atom={atom as PrimitiveAtom<PercentCellType>}
            {...props}
          />
        )}
        {cell.type === "growth" && (
          <GrowthCell
            atom={atom as PrimitiveAtom<PercentCellType>}
            {...props}
          />
        )}
        {cell.type === "multiple" && (
          <MultipleCell
            atom={atom as PrimitiveAtom<MultipleCellType>}
            {...props}
          />
        )}
      </DeferredRender>
    </div>
  );
});
