import { getCustomCategories } from "@/atoms/dealTracker/category";
import {
  dealTrackerDocumentAtom,
  getDealTrackerId,
} from "@/atoms/dealTracker/dealTracker";
import { auth, firestore } from "@/firebase";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";

export type DealNote = {
  id: string;
  text: string;
  createdAt: Timestamp;
};

export type DealContact = {
  id: string;
  name: string;
  email: string;
  phone: string;
  role: string;
  organization: string;
};

export type Deal = {
  id: string;
  dealTrackerId: string;
  creatorId: string;
  createdAt: Timestamp;
  establishedAt: Timestamp | null;
  status: "published" | "trashed";
  companyName: string;
  location: string;
  listingLink: string;
  brokerage: string;
  broker: string;
  categoryId: string | null;
  ndaCompleted: boolean;
  cimCompleted: boolean;
  purchasePrice: number;
  revenue: number;
  cashFlow: number;
  modelIds: string[] | null;
  pinnedModelId: string | null;
  remarks: string;
  industry: string;
  notes: DealNote[] | null;
  includesRealEstate: boolean;
  contacts: DealContact[] | null;
};

export const collectionRef = collection(firestore, "deal");

export const create = async (deal?: Partial<Deal>) => {
  const newDoc = {
    createdAt: Timestamp.now(),
    establishedAt: null,
    creatorId: auth?.currentUser?.uid || "unknown",
    dealTrackerId: "",
    status: "published",
    companyName: "Untitled Deal " + new Date().toLocaleDateString(),
    location: "",
    listingLink: "",
    brokerage: "",
    broker: "",
    categoryId: getCustomCategories()[0]?.id || null,
    ndaCompleted: false,
    cimCompleted: false,
    purchasePrice: 0,
    revenue: 0,
    cashFlow: 0,
    modelIds: null,
    pinnedModelId: null,
    remarks: "",
    industry: "",
    notes: null,
    includesRealEstate: false,
    contacts: null,
    ...deal,
  } satisfies Omit<Deal, "id">;

  return await addDoc(collectionRef, newDoc);
};

export const update = async (dealId: string, deal: Partial<Deal>) => {
  const { id, ...rest } = deal;
  await setDoc(doc(collectionRef, dealId || id), rest, { merge: true });
};

export const remove = async (dealId: string) => {
  await setDoc(
    doc(collectionRef, dealId),
    { status: "trashed" },
    { merge: true },
  );
};

export const useDeals = () => {
  const dealQuery = query(
    collectionRef,
    //orderBy("createdAt", "desc"),
    where("creatorId", "==", getDealTrackerId()),
    where("status", "==", "published"),
  );
  const [deals, loading, error] = useCollection(dealQuery);

  return [
    (deals?.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) || []) as Deal[],
    loading,
    error,
  ] as const;
};

export const useCreateDeal = () => {
  const [dealTracker] = useAtomValue(dealTrackerDocumentAtom);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const createDeal = async (deal?: Partial<Deal>) => {
    setLoading(true);

    try {
      const data = {
        ...deal,
        dealTrackerId: dealTracker!.id,
      };
      const dealRef = await create(data);
      setLoading(false);
      return dealRef;
    } catch (error: unknown) {
      console.log({ error });
      setError(error as Error);
    }
    setLoading(false);
  };

  return [createDeal, loading, error] as const;
};

export const useUpdateDeal = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const updateDeal = async (dealId: string, deal: Partial<Deal>) => {
    setLoading(true);
    try {
      await update(dealId, deal);
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [updateDeal, loading, error] as const;
};

export const useRemoveDeal = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const removeDeal = async (dealId: string) => {
    setLoading(true);
    try {
      await remove(dealId);
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [removeDeal, loading, error] as const;
};
