import { update as updateDeal } from "@/hooks/useDeals";
import {
  CellValueChangedEvent,
  StateUpdatedEvent,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { useAtom, useSetAtom } from "jotai";
import { memo, useCallback, useEffect, useRef } from "react";
import { css } from "vite-plugin-inline-css-modules";

import {
  agGridKeepRowsSyncedEffectAtom,
  agGridRefAtom,
  agGridStateAtom,
} from "@/atoms/dealTracker/agGrid";
import {
  getCategoryById,
  getPaletteFromId,
} from "@/atoms/dealTracker/category";
import { colDefs, defaultColDef } from "../columnDefinitions";

const styles = css`
  .root {
    --ag-wrapper-border-radius: 0 !important;
    --ag-font-family: var(--mantine-font-family);

    :global {
      .checkbox-cell-header {
        padding: 0 0 0 6px;
        font-size: 10px;
        text-align: center;

        &:after {
          content: "";
          position: absolute;
          z-index: 1;
          display: var(--ag-header-column-resize-handle-display);
          width: var(--ag-header-column-resize-handle-width);
          height: var(--ag-header-column-resize-handle-height);
          top: calc(50% - var(--ag-header-column-resize-handle-height) * 0.5);
          left: calc(100% - var(--ag-header-column-resize-handle-width));
          background-color: var(--ag-header-column-resize-handle-color);
        }

        .ag-header-cell-label {
          overflow: visible;
          text-overflow: initial;
        }
        .ag-sort-indicator-icon {
          padding-left: 0;
        }
        .ag-header-cell-text {
          width: 25px;
          text-align: center;
        }
      }
    }
  }
`;

export const Grid = memo(() => {
  useAtom(agGridKeepRowsSyncedEffectAtom);

  const isReadyRef = useRef(false);
  const setGridRef = useSetAtom(agGridRefAtom);
  const setGridState = useSetAtom(agGridStateAtom);
  const agGridRef = useRef<AgGridReact | null>(null);

  const onGridReady = useCallback(() => {
    isReadyRef.current = true;

    const gridColumnState = localStorage.getItem("gridColumnState");
    if (!gridColumnState) return;
    if (!agGridRef.current) return;

    agGridRef.current.api?.applyColumnState({
      state: JSON.parse(gridColumnState),
      applyOrder: true,
    });

    //turn on animations
    setTimeout(() => {
      agGridRef.current!.api.setGridOption("animateRows", true);
      agGridRef.current!.api.setGridOption(
        "suppressColumnMoveAnimation",
        false,
      );
    }, 20);
  }, []);

  const onStateUpdated = useCallback(
    (params: StateUpdatedEvent) => {
      console.log("Grid State updated", params.state);
      setGridState(params.state);

      if (!isReadyRef.current) return;
      if (!agGridRef.current) return;

      localStorage.setItem(
        "gridColumnState",
        JSON.stringify(agGridRef.current.api?.getColumnState()),
      );
    },
    [setGridState],
  );

  const onCellValueChanged = (event: CellValueChangedEvent) => {
    updateDeal(event.data.id, event.data);
    event.api.refreshCells({ force: true });
    event.api.onSortChanged();
  };

  useEffect(() => {
    setGridRef(agGridRef);
  }, [agGridRef, setGridRef]);

  //console.log("Grid rendered");

  return (
    <div
      className={"ag-theme-quartz " + styles.root}
      style={{
        width: "100%",
        height: "100%",
        justifySelf: "stretch",
        alignSelf: "stretch",
        flex: 1,
      }}
    >
      <AgGridReact
        suppressColumnMoveAnimation={true}
        animateRows={false}
        ref={agGridRef}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        onCellValueChanged={onCellValueChanged}
        onStateUpdated={onStateUpdated}
        suppressDragLeaveHidesColumns={false}
        getRowId={(params) => params.data.id}
        getRowStyle={(params) => {
          return {
            boxShadow:
              `inset 5px 0 0 0 ` +
              getPaletteFromId(
                getCategoryById(params.data.categoryId).paletteId,
              ).backgroundColor,
          };
        }}
        localeText={{
          noRowsToShow: `No deals created yet. Click the "Create Deal" button above to get started.`,
        }}
        loading={false}
        onGridReady={onGridReady}
        //tooltipHideDelay={0}
        tooltipShowDelay={0}
      />
    </div>
  );
});
