import { lazy } from "react";

export const lazyReactFallback: typeof lazy = (importer) => {
  return lazy(() =>
    importer().catch((error) => {
      console.warn("Error loading component:", error);
      return {
        default: (() => (
          <div>Could not load content. Please refresh and try again.</div>
        )) as any,
      };
    }),
  );
};
