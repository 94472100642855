import { Category, getPaletteFromId } from "@/atoms/dealTracker/category";
import { textFontFamily } from "@/theme";
import { Badge, BadgeProps } from "@mantine/core";

export const DealCategoryBadge = ({
  category,
  ...props
}: BadgeProps & { category: Category }) => {
  const palette = getPaletteFromId(category?.paletteId);

  return (
    <Badge
      size="lg"
      color={palette.backgroundColor}
      tt={"capitalize"}
      ff={textFontFamily}
      c={palette.fontColor}
      {...props}
    >
      {category?.label || "Unlabeled"}
    </Badge>
  );
};
