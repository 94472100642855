import { defaultCategories } from "@/atoms/dealTracker/category";
import { DealTracker, getDealTrackerId } from "@/atoms/dealTracker/dealTracker";
import { auth, firestore } from "@/firebase";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { useState } from "react";

export const collectionRef = collection(firestore, "dealTracker");

export const getDefaultDealTracker = () =>
  ({
    createdAt: serverTimestamp() as Timestamp,
    creatorId: auth?.currentUser?.uid || "unknown",
    status: "published",
    categories: [...defaultCategories],
  }) satisfies Omit<DealTracker, "id">;

export const create = async (dealTracker?: Omit<DealTracker, "id">) => {
  return await addDoc(collectionRef, {
    ...getDefaultDealTracker(),
    ...dealTracker,
    creatorId: auth?.currentUser?.uid ?? "unknown",
  });
};

export const update = async (dealTracker: Partial<DealTracker>) => {
  const { id, ...rest } = dealTracker;
  await setDoc(doc(collectionRef, id || getDealTrackerId()), rest, {
    merge: true,
  });
};

export const useCreateDealTracker = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const createDealTracker = async (dealTracker: Omit<DealTracker, "id">) => {
    setLoading(true);
    try {
      return await create(dealTracker);
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [createDealTracker, loading, error] as const;
};

export const useUpdateDealTracker = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const updateDealTracker = async (deal: Partial<DealTracker>) => {
    setLoading(true);
    try {
      await update(deal);
    } catch (error: unknown) {
      setError(error as Error);
    }
    setLoading(false);
  };

  return [updateDealTracker, loading, error] as const;
};
