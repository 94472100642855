import { Group, Stack, StackProps, Text } from "@mantine/core";

export const Sidebar = ({
  children,
  size,
  ...props
}: StackProps & {
  children: React.ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | number;
}) => {
  const width = {
    xs: 240,
    sm: 320,
    md: 368,
    lg: 400,
    xl: 432,
  }[size || "md"];

  return (
    <Stack
      w={width || size}
      h={"100dvh"}
      gap={0}
      bg="gray.1"
      style={{
        borderRight: "1px solid #ccc",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
      }}
      pos={"relative"}
      {...props}
    >
      {children}
    </Stack>
  );
};

Sidebar.Title = ({
  children,
  rightSection,
}: {
  children: React.ReactNode;
  rightSection: React.ReactNode;
}) => {
  return (
    <Group
      justify="space-between"
      pb={"xs"}
      style={{
        borderBottom: "1px solid #ccc",
      }}
    >
      <Text size="sm" fw={600}>
        {children}
      </Text>
      {rightSection}
    </Group>
  );
};
