import { dealsAtom } from "@/atoms/dealTracker/deals";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { useAtomValue } from "jotai";
import { Grid } from "./Grid/Grid";
import { Toolbar } from "./Toolbar/Toolbar";

export const Main = () => {
  const [, loading, error] = useAtomValue(dealsAtom);

  if (loading) return <div>...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <LayoutWorkstation.Main>
      <LayoutWorkstation.Header>
        <Toolbar />
        <LayoutWorkstation.Header.Menu />
      </LayoutWorkstation.Header>
      <LayoutWorkstation.Content>
        <Grid />
      </LayoutWorkstation.Content>
    </LayoutWorkstation.Main>
  );
};
