import { Deal } from "@/hooks/useDeals";
import { GridState } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { MutableRefObject } from "react";
import { dealsAtom } from "./deals";

export const agGridRefAtom = atom<
  MutableRefObject<AgGridReact | null> | undefined
>(undefined);

export const agGridStateAtom = atom<GridState | undefined>(undefined);

export const agGridColumnVisibilityAndOrderAtom = atom<string[]>([]);

export const agGridKeepRowsSyncedEffectAtom = atomEffect((get) => {
  // runs on mount or whenever someAtom changes
  //const value = get(someAtom);
  //loggingService.setValue(value);
  const ref = get(agGridRefAtom);
  const [deals, , , snapshot] = get(dealsAtom);

  if (!deals) return;
  if (!ref || !ref.current) return;

  const transaction: {
    add: Deal[];
    remove: Deal[];
    update: Deal[];
  } = { add: [], remove: [], update: [] };

  snapshot?.docChanges().forEach((change) => {
    const deal = { ...change.doc.data(), id: change.doc.id } as Deal;

    if (change.type === "added") {
      transaction.add.push(deal);
    } else if (change.type === "removed") {
      transaction.remove.push(deal);
    } else if (change.type === "modified") {
      transaction.update.push(deal);
    }
  });

  const gridApi = ref.current.api;
  gridApi.applyTransaction(transaction);

  //console.log({ deals, transaction });
});
