import { customCategoriesAtom } from "@/atoms/dealTracker/category";
import { DealCategoryBadge } from "@/components/DealCategoryBadge/DealCategoryBadge";
import { CustomCellEditorProps } from "@ag-grid-community/react";
import { Select } from "@mantine/core";
import { useAtomValue } from "jotai";
import { memo, useEffect, useRef, useState } from "react";

export const CategoryEditor = memo(
  ({ value, onValueChange, stopEditing }: CustomCellEditorProps) => {
    const categories = useAtomValue(customCategoriesAtom);
    const refContainer = useRef<HTMLInputElement>(null);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
      const ele = refContainer.current;

      ele?.focus();

      setSearchValue("");

      // stop propagation of enter key
      const onKeydown = (event: KeyboardEvent) => {
        if (event.key !== "Enter") return;
        event.stopPropagation();
        // find option data-combobox-option="true" and click it and aria-selected="true"
        const option = document.querySelector(
          `[data-combobox-option="true"][aria-selected="true"]`,
        ) as HTMLElement;

        if (!option) return;
        option.click();
      };

      ele?.addEventListener("keydown", onKeydown);

      return () => {
        ele?.removeEventListener("keydown", onKeydown);
      };
    }, []);

    return (
      <Select
        onOptionSubmit={(value) => {
          onValueChange(value);
          stopEditing();
        }}
        nothingFoundMessage="No status found"
        searchable
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        dropdownOpened={true}
        autoFocus
        ref={refContainer}
        w="100%"
        placeholder="Search..."
        value={value}
        data={categories.map((c) => ({ ...c, value: c.id, label: c.label }))}
        renderOption={({ option, checked }) => (
          <DealCategoryBadge
            category={categories.find((c) => c.id === option.value)!}
            variant={checked ? "outline" : "filled"}
          />
        )}
        maxDropdownHeight={238}
      />
    );
  },
);
