import { Stack } from "@mantine/core";
import { CashRequired } from "./CashRequired";
import { SourcesAndUses } from "./SourcesAndUses";

export const PanelOverview = () => {
  return (
    <Stack>
      <CashRequired />
      <SourcesAndUses />
    </Stack>
  );
};
