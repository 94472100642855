import { Route } from "@/paths";
import { ActionIcon, Group, Text } from "@mantine/core";
import { ReactNode } from "react";
import { BiChevronRight } from "react-icons/bi";
import { FaCircleUser } from "react-icons/fa6";
import { Link, To } from "react-router-dom";
import { Logo } from "../Logo/Logo";

export const defaultHeaderHeight = 42;

export const Header = ({
  children,
  headerHeight = defaultHeaderHeight,
}: {
  children: ReactNode;
  headerHeight?: number;
}) => {
  return (
    <Group
      h={headerHeight}
      align="center"
      style={{
        borderBottom: "1px solid #ddd",
      }}
      bg="gray.1"
      justify="space-between"
      gap={"xs"}
    >
      {children}
    </Group>
  );
};

Header.Breadcrumbs = ({ children }: { children: ReactNode }) => {
  return (
    <Group px={"xs"} h={"100%"} w={"100%"} align="center" gap={6}>
      <Logo w={56} variant="dark" to={Route.DealTracker} />
      <Header.BreadcrumbSeperator />
      {children}
    </Group>
  );
};

Header.BreadcrumbTitle = ({
  children,
  to,
}: {
  children: ReactNode;
  to?: To;
}) => {
  return (
    <Text
      mt={".13em"}
      fw={600}
      fz={"md"}
      {...(to ? { component: Link, to } : { to: "" })}
    >
      {children}
    </Text>
  );
};

Header.BreadcrumbSeperator = () => {
  return <BiChevronRight />;
};

Header.Menu = () => {
  return (
    <Group gap={"xs"}>
      {/* <Button
        visibleFrom="lg"
        size="xs"
        variant="outline"
        leftSection={<RiGraduationCapFill size={16} />}
        color="brand"
      >
        Learning Center
      </Button> */}
      <ActionIcon
        variant="subtle"
        size={"lg"}
        component={Link}
        to={Route.Profile}
        target="_blank"
      >
        <FaCircleUser size={20} />
      </ActionIcon>
    </Group>
  );
};
