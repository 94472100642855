import { firestore } from "@/firebase";
import { Deal } from "@/hooks/useDeals";
import { atomWithQuery, authStateAtom } from "@/utils/atomWithFirebase";
import { collection, query, where } from "firebase/firestore";
import { atom } from "jotai";

export const collectionRef = collection(firestore, "deal");

export const queryAtom = atom((get) => {
  const [user] = get(authStateAtom);

  return atomWithQuery<Deal>(
    query(
      collectionRef,
      //orderBy("createdAt", "desc"),
      where("creatorId", "==", user?.uid || ""),
      where("status", "==", "published"),
    ),
  );
});

export const dealsAtom = atom((get) => {
  return get(get(queryAtom));
});
