import { defaultWidth } from "./Column";
import { PercentCell, PercentCellProps } from "./PercentCell";

const padding = 16;
export const defaultGrowthCellWidth = defaultWidth - padding * 2;

export const GrowthCell = (props: PercentCellProps) => {
  return (
    <PercentCell
      ta={"center"}
      justifyContent={"center"}
      style={{
        textAlign: "center",
        width: defaultGrowthCellWidth,
        boxShadow: `#DEE2E6 ${padding}px 0 0 0, #DEE2E6 -${padding}px 0 0 0`,
        //borderRight: `${padding}px solid #DEE2E6`,
        //borderLeft: `${padding}px solid #DEE2E6`,
      }}
      {...props}
    />
  );
};
