import { CustomCellRendererProps } from "@ag-grid-community/react";
import { useMemo } from "react";
import { HiExternalLink } from "react-icons/hi";

export const ListingLinkRenderer = (props: CustomCellRendererProps) => {
  const value = useMemo(() => props.value, [props.value]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "calc(100% - 25px)",
        }}
      >
        {value
          .replace("www.", "")
          .replace("http://", "")
          .replace("https://", "") || ""}
      </span>

      {isValidUrl(value) && (
        <a href={value || ""} target="_blank">
          <HiExternalLink
            color="gray"
            size={16}
            style={{
              position: "relative",
              top: 3,
            }}
          />
        </a>
      )}
    </div>
  );
};

const isValidUrl = (url: string) => {
  return url.includes("http") || url.includes("www");
};
