import { MathResult } from "@/financeModels/types";
import { evaluteWithErrors, isNumber, toNumberOrNull } from "./math";

const dollarFormater = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "narrowSymbol",
  // @ts-expect-error Missing signDisplay option is necessary for formatting negative amounts.
  signDisplay: "negative",
});

export const formatDollar = (amount: number) => {
  return dollarFormater.format(amount);
};

export const formatDollarCell = (amount: number) => {
  const isNegative = amount < 0;
  const prefix = isNegative ? "$(" : "$ ";
  const suffix = isNegative ? ")" : " ";
  const formatted = formatDollar(Math.abs(amount))
    .replace("$", "")
    .replace(".00", ".ØØ");

  return `${prefix}${formatted}${suffix}`;
};

export const percentInputToNumber = (value: string | number) => {
  if (value === "") return null;
  const number = evaluteWithErrors<number>("val / 100", { val: Number(value) });

  return toNumberOrNull(number);
};

export const numberToPercentInput = (value: MathResult) => {
  if (value === null) return "";

  const percent = toNumberOrNull(
    evaluteWithErrors<number>("val * 100", { val: value }),
  );

  return percent === null ? "" : percent;
};

export const formatDecimalPercent = (
  value: MathResult,
  fractionDigits: number = 2,
) => {
  if (!isNumber(value)) return "";
  return `${(value * 100).toFixed(fractionDigits).replace(/\.?0+$/, "")}%`;
};

export const formatDecimalPercentTwoPlaces = (
  value: MathResult,
  fractionDigits: number = 2,
) => {
  if (!isNumber(value)) return "";
  return `${(value * 100).toFixed(fractionDigits)}%`;
};
