import { dealDocumentAtom } from "@/atoms/deal";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { Group, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { Timestamp } from "firebase/firestore";
import { useAtom } from "jotai";
import { useDealTextInput } from "./useDealTextInput";

export const Company = () => {
  const [[deal], setDeal] = useAtom(dealDocumentAtom);
  const companyName = useDealTextInput("companyName");
  const location = useDealTextInput("location");
  const industry = useDealTextInput("industry");

  return (
    <LayoutWorkstation.Panel label="Company">
      <Group>
        <TextInput {...companyName} label="Company Name" placeholder="" />
        <TextInput {...location} label="Location" placeholder="" />
        <DateInput
          w={160}
          label="Year Established"
          valueFormat="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
          value={deal?.establishedAt?.toDate()}
          onChange={(date) => {
            if (!date) return;
            setDeal(
              { ...deal, establishedAt: Timestamp.fromDate(date) },
              { merge: true },
            );
          }}
        />
        <TextInput {...industry} label="Industry" placeholder="" />
      </Group>
    </LayoutWorkstation.Panel>
  );
};
