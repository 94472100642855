import { collectionRef, Deal } from "@/hooks/useDeals";
import { atomWithDocument } from "@/utils/atomWithFirebase";
import { doc, SetOptions } from "firebase/firestore";
import { atom } from "jotai";

const getDealId = () => {
  return window.location.pathname.split("/deal/")[1].split("/")[0];
};

export const dealDocumentAtom = atom(
  (get) => get(atomWithDocument<Deal>(doc(collectionRef, getDealId()))),
  (_get, set, deal: Partial<Deal>, options?: SetOptions) =>
    set(atomWithDocument<Deal>(doc(collectionRef, getDealId())), deal, options),
);

export const atomWithDeal = (dealId: string) => {
  return atom(
    (get) => get(atomWithDocument<Deal>(doc(collectionRef, dealId))),
    (_get, set, deal: Partial<Deal>, options?: SetOptions) =>
      set(atomWithDocument<Deal>(doc(collectionRef, dealId)), deal, options),
  );
};
