import { NumberInput, NumberInputProps } from "@mantine/core";

export const InputYears = ({
  value,
  onChange,
  labelWidth = 180,
  label,
  ...props
}: NumberInputProps & {
  labelWidth?: number;
}) => {
  return (
    <NumberInput
      allowDecimal={false}
      label={label}
      value={value}
      onChange={onChange}
      rightSection={props.rightSection || "years"}
      rightSectionWidth={props.rightSectionWidth || 60}
      min={0}
      max={100}
      step={1}
      ta={"right"}
      placeholder="0"
      hideControls
      styles={{
        root: { display: "flex", alignItems: "center" },
        label: { width: labelWidth, textAlign: "left" },
        wrapper: { flex: 1 },
        input: { textAlign: "right" },
      }}
      {...props}
    />
  );
};
