import { auth } from "@/firebase";
import {
  arrayRemove,
  arrayUnion,
  doc,
  documentId,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { Deal, useUpdateDeal } from "./useDeals";
import {
  Model,
  collectionRef,
  useCloneModel,
  useCreateModel,
  useRemoveModel,
} from "./useModels";

import { collectionRef as dealCollectionRef } from "./useDeals";

export const useDeal = (dealId: string) => {
  const [deal, loading, error] = useDocument(doc(dealCollectionRef, dealId));

  return [
    !deal
      ? undefined
      : ({
          ...deal?.data(),
          id: deal?.id,
        } as Deal),
    loading,
    error,
  ] as const;
};

export const useDealModels = (modelIds: string[]) => {
  const isEmpty = !Array.isArray(modelIds) || !(modelIds.length > 0);

  const q = isEmpty
    ? null
    : query(
        collectionRef,
        where(documentId(), "in", modelIds),
        where("creatorId", "==", auth.currentUser?.uid),
        where("status", "==", "published"),
      );

  const [snapshot, loading, error] = useCollection(q);
  const [models, setModels] = useState<Model[]>([]);

  useEffect(() => {
    if (!snapshot) {
      setModels([]);
      return;
    }
    const newModels = snapshot.docs.map(
      (doc) => ({ id: doc.id, ...doc.data() }) as Model,
    );
    setModels(newModels);
  }, [snapshot]);

  const isSettingModelsValue =
    snapshot?.docs.length && models.length !== snapshot?.docs.length;

  return [models, loading || isSettingModelsValue, error] as const;
};

export const useCreateDealModel = () => {
  const [createModel] = useCreateModel();
  const [updateDeal] = useUpdateDeal();

  const createDealModel = async (dealId: string) => {
    const modelRef = await createModel();

    if (!modelRef) return;

    // @ts-expect-error - modelIds needs arrayUniion to add
    await updateDeal(dealId, {
      modelIds: arrayUnion(modelRef.id),
    } as Deal);
    return modelRef;
  };

  return [createDealModel] as const;
};

export const useRemoveDealModel = () => {
  const [removeModel] = useRemoveModel();
  const [updateDeal] = useUpdateDeal();

  const removeDealModel = async (dealId: string, modelId: string) => {
    await removeModel(modelId);
    // @ts-expect-error - modelIds needs arrayRemove to add
    await updateDeal(dealId, {
      modelIds: arrayRemove(modelId),
    } as Deal);
  };

  return [removeDealModel] as const;
};

export const useCloneDealModel = () => {
  const [cloneModel] = useCloneModel();
  const [updateDeal] = useUpdateDeal();

  const cloneDealModel = async (dealId: string, modelId: string) => {
    const modelRef = await cloneModel(modelId);

    if (!modelRef) return;

    // @ts-expect-error - modelIds needs arrayUniion to add
    await updateDeal(dealId, {
      modelIds: arrayUnion(modelRef.id),
    } as Deal);

    return modelRef;
  };

  return [cloneDealModel] as const;
};
