import { BoxComponentProps, Group } from "@mantine/core";
import { ReactNode } from "react";
import { defaultHeight } from "./cell/Wrapper";

export const Row = ({
  children,
  ...props
}: BoxComponentProps & { children: ReactNode }) => {
  return (
    <Group
      wrap="nowrap"
      gap={0}
      justify="flex-start"
      {...props}
      h={defaultHeight}
    >
      {children}
    </Group>
  );
};
