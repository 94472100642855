import {
  iterationCountAtom,
  suggestedIterationCountAtom,
} from "@/financeModels/owasco/iterations";
import { Button, Modal, NumberInput, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useAtom, useAtomValue } from "jotai";

export const ProjectionYearModal = ({
  disclosure,
}: {
  disclosure: ReturnType<typeof useDisclosure>;
}) => {
  const [iterationCount, setIterationCount] = useAtom(iterationCountAtom);
  const suggestedIterationCount = useAtomValue(suggestedIterationCountAtom);

  const [opened, { close }] = disclosure;

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      iterationCount: iterationCount || suggestedIterationCount,
    },
  });

  return (
    <Modal opened={opened} onClose={close} size={"sm"} withCloseButton={false}>
      <form
        onSubmit={form.onSubmit((values) => {
          setIterationCount(values.iterationCount);
          close();
        })}
      >
        <Stack>
          <NumberInput
            size="lg"
            label=" How many years to project forward?"
            min={1}
            max={50}
            suffix=" years"
            {...form.getInputProps("iterationCount")}
          />

          <Text size="sm">
            The projection table will be created for the number of years
            specified above. You can change this later.
          </Text>

          <Button fullWidth type="submit">
            Change Projection
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};
