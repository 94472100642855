import { agGridRefAtom, agGridStateAtom } from "@/atoms/dealTracker/agGrid";
import {
  ActionIcon,
  Button,
  Group,
  SimpleGrid,
  Stack,
  Switch,
  Tooltip,
} from "@mantine/core";
import { useAtomValue } from "jotai";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { colDefs } from "../columnDefinitions";

export const ColumnToggle = () => {
  const gridRef = useAtomValue(agGridRefAtom);
  const gridState = useAtomValue(agGridStateAtom);

  return (
    <Stack>
      <Group gap={"xs"}>
        <Button
          size="sm"
          flex={1}
          onClick={() => {
            if (!gridRef?.current) return;
            gridRef.current.api.setColumnsVisible(
              colDefs.map((col) => col.field!),
              false,
            );
          }}
          disabled={
            gridState?.columnVisibility?.hiddenColIds.length === colDefs.length
          }
        >
          Hide All
        </Button>
        <Button
          size="sm"
          flex={1}
          onClick={() => {
            if (!gridRef?.current) return;
            gridRef.current.api.setColumnsVisible(
              colDefs.map((col) => col.field!),
              true,
            );
          }}
          disabled={!gridState?.columnVisibility?.hiddenColIds.length}
        >
          Show All
        </Button>
        <Tooltip label="Restore Defaults">
          <ActionIcon
            h={37}
            w={37}
            variant="light"
            size="lg"
            onClick={() => {
              gridRef?.current!.api.resetColumnState();
            }}
          >
            <MdOutlineSettingsBackupRestore />
          </ActionIcon>
        </Tooltip>
      </Group>
      <SimpleGrid cols={2}>
        {colDefs
          .sort((a, b) => (a.field || "").localeCompare(b.field || ""))
          .map((col) => (
            <Switch
              key={col.field}
              label={(
                col.headerName || camelCaseToTitleCase(col.field || "")
              ).replace("🏢", "Real Estate Included")}
              checked={
                !gridState?.columnVisibility?.hiddenColIds.includes(col.field!)
              }
              onChange={(event) => {
                if (!gridRef?.current) return;
                const checked = event.currentTarget.checked;
                gridRef.current.api.setColumnsVisible([col.field!], checked);
              }}
            />
          ))}
      </SimpleGrid>
    </Stack>
  );
};

const camelCaseToTitleCase = (str: string) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};
