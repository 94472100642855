import { BigDollarInput } from "@/components/BigDollarInput/BigDollarInput";
import { PaymentMeta } from "@/financeModels/owasco/types";
import { toNumberOrNull } from "@/utils/math";
import { PrimitiveAtom, useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { FaStar } from "react-icons/fa";
import { RiPencilFill } from "react-icons/ri";
import { getFocusableElement } from "../CellGrid/hooks/useTabCoordinates";

export const ExtraPaymentCell = ({
  index,
  isEditing,
  paymentsMetaAtom,
  onComplete,
  onSelect,
  children,
  submitTriggerRef,
}: {
  index: number;
  isEditing: boolean;
  paymentsMetaAtom: PrimitiveAtom<Record<number, PaymentMeta>>;
  onComplete: () => void;
  onSelect: (index: number) => void;
  submitTriggerRef: React.MutableRefObject<() => void>;
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [paymentsMeta, setPaymentsData] = useAtom(paymentsMetaAtom);
  const value = paymentsMeta[index]?.extraPayment || 0;
  const [inputValue, setInputValue] = useState(value);

  const onSubmit = () => {
    const newPaymentsMeta = {
      ...paymentsMeta,
      [index]: { ...paymentsMeta[index], extraPayment: inputValue },
    };
    setPaymentsData(newPaymentsMeta);
    onComplete();
  };

  useEffect(() => {
    setInputValue(value);

    setTimeout(() => {
      ref.current &&
        (getFocusableElement(ref.current) as HTMLInputElement).select();
    }, 30);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, value]);

  if (isEditing) submitTriggerRef.current = onSubmit;

  return (
    <>
      {!isEditing && (
        <div
          style={{
            display: "inline-block",
            color: "var(--mantine-color-brand-6)",
            cursor: "pointer",
            borderBottom: "1px dashed var(--mantine-color-gray-4)",
          }}
          onClick={() => {
            setInputValue(0);
            onSelect(index);
          }}
          tabIndex={1}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setInputValue(0);
              onSelect(index);
            }
          }}
        >
          {children}
          {value <= 0 ? (
            <RiPencilFill
              style={{ marginLeft: 3, color: "var(--mantine-color-gray-7)" }}
            />
          ) : (
            <FaStar
              style={{ marginLeft: 3, color: "var(--mantine-color-blue-4)" }}
            />
          )}
        </div>
      )}
      {isEditing && (
        <div
          ref={ref}
          style={{
            position: "relative",
          }}
        >
          <BigDollarInput
            size="sm"
            value={toNumberOrNull(inputValue) ?? 0}
            onChange={(value) => setInputValue(toNumberOrNull(value) || 0)}
            autoFocus
            onFocus={(e) => setTimeout(() => e.currentTarget.select(), 100)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                onSubmit();
              }

              if (e.key === "Escape") {
                onComplete();
              }
            }}
            onBlur={onSubmit}
          />
        </div>
      )}
    </>
  );
};
