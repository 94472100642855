import { createItemizationAtom } from "@/financeModels/owasco/itemization";
import { ItemizationLineItem } from "@/financeModels/owasco/types";
import { ActionIcon, Group, Text, TextInput } from "@mantine/core";
import { useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import { MdSubdirectoryArrowRight } from "react-icons/md";
import { css } from "vite-plugin-inline-css-modules";

const styles = css`
  .icon-on-hover {
    cursor: pointer;
    svg {
      display: none;
    }

    &:hover {
      svg {
        display: inline-block;
      }
    }
  }
`;

export const LineItemLabel = ({
  lineItem,
  itemizationAtom,
}: {
  itemizationAtom: ReturnType<typeof createItemizationAtom>;

  lineItem: ItemizationLineItem;
}) => {
  const itemization = useAtomValue(itemizationAtom);
  const deleteLineItem = useSetAtom(itemization.deleteLineItemAtom);
  const renameLineItem = useSetAtom(itemization.renameLineItemAtom);

  const [isEditing, setIsEditing] = useState<null | string>(null);

  const onSubmit = () => {
    if (isEditing === null) return;
    renameLineItem({ ...lineItem, label: isEditing });
    setIsEditing(null);
  };

  return (
    <Group gap={"xs"}>
      <MdSubdirectoryArrowRight />
      {isEditing === null && (
        <Text
          className={styles["icon-on-hover"]}
          truncate="end"
          size="sm"
          w="calc(100% - 58px)"
          onClick={() => {
            setIsEditing(lineItem.label);
          }}
        >
          <FaPencil size={12} style={{ marginRight: 10 }} />
          {lineItem.label || "(Untitled)"}
        </Text>
      )}

      {isEditing !== null && (
        <TextInput
          value={isEditing}
          onChange={(event) => {
            setIsEditing(event.currentTarget.value);
          }}
          onBlur={() => {
            onSubmit();
          }}
          autoFocus
          onKeyUp={(event) => {
            if (event.key !== "Enter") return;
            onSubmit();
          }}
        />
      )}

      <ActionIcon
        size={"sm"}
        variant="light"
        color="accent.4"
        aria-label="Delete Item"
        tabIndex={999999}
        onClick={() => {
          deleteLineItem(lineItem.id);
        }}
        ml="auto"
      >
        <FaTrashAlt size={12} />
      </ActionIcon>
    </Group>
  );
};
