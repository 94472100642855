import { atom } from "jotai";
import { agGridStateAtom } from "./agGrid";
import { dealTrackerDocumentAtom } from "./dealTracker";
import { dealsAtom } from "./deals";

export type Category = {
  id: string;
  label: string;
  paletteId: string;
};

export const categoryCountAtom = atom((get) => {
  const [deals] = get(dealsAtom);

  return (deals || []).reduce(
    (acc, deal) => {
      acc["all"] = acc["all"] ? acc["all"] + 1 : 1;
      if (deal.categoryId !== null) {
        acc[deal.categoryId] = acc[deal.categoryId]
          ? acc[deal.categoryId] + 1
          : 1;
      }
      return acc;
    },
    {} as Record<string, number>,
  );
});

let lastKnownCustomCategoriesCache: Category[] = [];

export const customCategoriesAtom = atom((get) => {
  const categories = get(dealTrackerDocumentAtom)?.[0]?.categories || [];
  lastKnownCustomCategoriesCache = categories;
  return categories;
});

export const getCustomCategories = () => {
  return lastKnownCustomCategoriesCache;
};

export const getCategoryById = (id: string) => {
  return (lastKnownCustomCategoriesCache.find((c: Category) => c.id === id) || {
    id: "not found",
    label: "",
    paletteId: "",
  }) as Category;
};

export const getCategoryIndex = (id: string) => {
  return (
    lastKnownCustomCategoriesCache.findIndex((c: Category) => c.id === id) || 0
  );
};

export const allCategoriesAtom = atom((get) => {
  return [allCategory, ...get(customCategoriesAtom)];
});

export const filteredCategoryIdAtom = atom((get) => {
  const gridState = get(agGridStateAtom);
  return gridState?.filter?.filterModel?.categoryId?.filter || "all";
});

export const filteredCategoryAtom = atom((get) => {
  const categories = get(dealTrackerDocumentAtom)?.[0]?.categories || [];
  const filteredCategoryId = get(filteredCategoryIdAtom);
  return categories.find((c) => c.id === filteredCategoryId);
});

export const activeCategoryPaletteAtom = atom((get) => {
  const activeCategory = get(filteredCategoryAtom);
  return getPaletteFromId(activeCategory?.paletteId);
});

export const allCategory: Category = {
  id: "all",
  label: "All",
  paletteId: "gray",
};

export const defaultCategories: Category[] = [
  { id: "new", label: "New", paletteId: "sunnyYellow" },
  { id: "working", label: "Working", paletteId: "lavender" },
  { id: "due-diligence", label: "Due Diligence", paletteId: "royalBlue" },
  { id: "ioi-loi", label: "IOI/LOI", paletteId: "limeGreen" },
  { id: "archived", label: "Archived", paletteId: "midnightBlue" },
];

export const getPaletteFromId = (id: string | undefined) => {
  return categoryPalettes.find((c) => c.id === id) || categoryPalettes[0];
};

export const categoryPalettes = [
  {
    id: "gray",
    backgroundColor: "#BDBDBD",
    fontColor: "#424242",
  },
  {
    id: "lipstick",
    backgroundColor: "#D81159",
    fontColor: "#FAE1E4",
  },
  {
    id: "coral",
    backgroundColor: "#FF6B6B",
    fontColor: "#802525",
  },
  {
    id: "peach",
    backgroundColor: "#F9A07A",
    fontColor: "#803D24",
  },
  {
    id: "orange",
    backgroundColor: "#FFA500",
    fontColor: "#804D00",
  },
  {
    id: "mango",
    backgroundColor: "#FFC107",
    fontColor: "#806600",
  },
  {
    id: "sunnyYellow",
    backgroundColor: "#F6D06F",
    fontColor: "#7A5A1B",
  },
  {
    id: "lemonYellow",
    backgroundColor: "#FFEB3B",
    fontColor: "#7F6B00",
  },
  {
    id: "limeGreen",
    backgroundColor: "#B4E197",
    fontColor: "#2F3B1A",
  },
  {
    id: "oliveGreen",
    backgroundColor: "#6E7E56",
    fontColor: "#323A1D",
  },
  {
    id: "sageGreen",
    backgroundColor: "#5E8A71",
    fontColor: "#254231",
  },

  {
    id: "teal",
    backgroundColor: "#4DB6AC",
    fontColor: "#16504B",
  },
  {
    id: "royalBlue",
    backgroundColor: "#00539C",
    fontColor: "#CEC8FB",
  },
  {
    id: "skyBlue",
    backgroundColor: "#7FDBFF",
    fontColor: "#2E7EBD",
  },
  {
    id: "babyBlue",
    backgroundColor: "#A6E3E9",
    fontColor: "#3E8A8F",
  },
  {
    id: "lavender",
    backgroundColor: "#A998F4",
    fontColor: "#382C91",
  },
  {
    id: "pastelPink",
    backgroundColor: "#FDC5F5",
    fontColor: "#8F508E",
  },
  {
    id: "lightPink",
    backgroundColor: "#FF99C8",
    fontColor: "#9F2F69",
  },
  {
    id: "peachPink",
    backgroundColor: "#F79C99",
    fontColor: "#9E3735",
  },
  {
    id: "burgundy",
    backgroundColor: "#3C1D32",
    fontColor: "#A06A8E",
  },
  {
    id: "forestGreen",
    backgroundColor: "#2D4844",
    fontColor: "#8AB2AE",
  },
  {
    id: "midnightBlue",
    backgroundColor: "#2E2A54",
    fontColor: "#CEC8FB",
  },
];
