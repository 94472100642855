export const Route = {
  Home: "/",
  Login: "/login",
  Signup: "/signup",
  Learn: "/learn",
  NotFound: "/*",
  AuthAction: "/auth/action",
  TermOfService: "/terms-of-service",
  PrivacyPolicy: "/privacy-policy",
  AboutUs: "/about-us",
  Profile: "/profile",
  ForgotPassword: "/forgot-password",
  Model: "/model/:modelId",
  ModelTab: "/model/:modelId/:modelTabId",
  Deal: "/deal/:dealId",
  DealTracker: "/deal-tracker",
} as const;

export type RouteType = (typeof Route)[keyof typeof Route];
