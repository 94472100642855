import { Box, BoxComponentProps } from "@mantine/core";
import { defaultHeaderHeight } from "./Header";

export const Content = ({
  children,
  ...props
}: BoxComponentProps & { children: React.ReactNode }) => {
  return (
    <Box h={`calc(100dvh - ${defaultHeaderHeight}px)`} {...props}>
      {children}
    </Box>
  );
};
