import { auth } from "@/firebase";
import { Route } from "@/paths";
import { getParameterByName } from "@/utils/url";
import { Alert, Button, Card, PasswordInput, Stack, Text } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import {
  applyActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutFullscreen } from "../LayoutFullscreen/LayoutFullscreen";

let ranOnce = false;

export const PageAuthAction = () => {
  const mode = getParameterByName("mode") || "verifyEmail";
  const actionCode = getParameterByName("oobCode");
  const lang = getParameterByName("lang") || "en";
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log({ mode, actionCode, lang });

    if (!mode) {
      setError("Missing parameters in URL.");
      return;
    }
  }, []);

  if (error) {
    return (
      <LayoutFullscreen>
        <div>{error}</div>
      </LayoutFullscreen>
    );
  }

  if (mode === "verifyEmail") {
    return <VerifyEmail />;
  }

  if (mode === "resetPassword") {
    return <ResetPassword actionCode={actionCode!} />;
  }

  if (mode === "recoverEmail") {
    return <RecoverEmail />;
  }

  return (
    <LayoutFullscreen>
      <div>Unknown Mode</div>
    </LayoutFullscreen>
  );
};

const VerifyEmail = () => {
  const navigate = useNavigate();
  const oobCode = getParameterByName("oobCode");
  const [actionState, setActionState] = useState<
    "pending" | "success" | "error"
  >("pending");

  useEffect(() => {
    if (ranOnce) return;
    ranOnce = true;

    applyActionCode(auth, oobCode!)
      .then(() => {
        setActionState("success");

        setTimeout(() => {
          navigate(Route.DealTracker, {
            replace: true,
          });
        }, 3000);
      })
      .catch((e) => {
        console.error("Error", e);
        setActionState("error");
      });
  }, []);

  return (
    <LayoutFullscreen>
      <Card withBorder w={500} ta={"center"}>
        <Stack>
          {actionState === "pending" && <div>Verifying email...</div>}
          {actionState === "success" && (
            <>
              <Text fz={"xl"}>Email Verfied!</Text>
              <Text fz={"sm"}>You will be redirected shortly.</Text>
            </>
          )}
          {actionState === "error" && (
            <>
              <Text fz={"lg"}>Link is invalid or expired.</Text>
              <Text fz={"sm"}>Please verify your email address again.</Text>
            </>
          )}
        </Stack>
      </Card>
    </LayoutFullscreen>
  );
};

const ResetPassword = ({ actionCode }: { actionCode: string }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useInputState("");
  const [confirmPassword, setConfirmPassword] = useInputState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    verifyPasswordResetCode(auth, actionCode)
      .then(() => {
        confirmPasswordReset(auth, actionCode, password)
          .then(() => {
            navigate(Route.Login, {
              replace: true,
              state: { message: "Password reset successful. Please login." },
            });
          })
          .catch(() => {
            setError(
              "Error occurred during confirmation. The code might have expired or the password is too weak.",
            );
            setLoading(false);
          });
      })
      .catch(() => {
        setError(
          "Invalid or expired action code. Please try password reset again.",
        );
        setLoading(false);
      });
  };

  return (
    <LayoutFullscreen>
      <Card withBorder w={500}>
        <Stack>
          <Text fz={"lg"}>Reset Password</Text>
          <form onSubmit={onSubmit}>
            <Stack gap={4}>
              <PasswordInput
                value={password}
                onChange={setPassword}
                placeholder="Your password"
                label="Password"
                required
              />

              <PasswordInput
                value={confirmPassword}
                onChange={setConfirmPassword}
                placeholder="Your password"
                label="Confirm Password"
                required
              />
              <Button loading={loading} type="submit" my={"sm"}>
                Reset Password
              </Button>
            </Stack>
          </form>
          {error && <Alert color="red">{error}</Alert>}
        </Stack>
      </Card>
    </LayoutFullscreen>
  );
};

const RecoverEmail = () => {
  // function handleRecoverEmail(auth: Auth, actionCode: string, lang: string) {
  //   // Localize the UI to the selected language as determined by the lang
  //   // parameter.
  //   let restoredEmail = null;
  //   // Confirm the action code is valid.
  //   checkActionCode(auth, actionCode)
  //     .then((info) => {
  //       // Get the restored email address.
  //       restoredEmail = info["data"]["email"];

  //       // Revert to the old email.
  //       return applyActionCode(auth, actionCode);
  //     })
  //     .then(() => {
  //       // Account email reverted to restoredEmail

  //       // TODO: Display a confirmation message to the user.

  //       // You might also want to give the user the option to reset their password
  //       // in case the account was compromised:
  //       sendPasswordResetEmail(auth, restoredEmail)
  //         .then(() => {
  //           // Password reset confirmation sent. Ask user to check their email.
  //         })
  //         .catch((error) => {
  //           // Error encountered while sending password reset code.
  //         });
  //     })
  //     .catch((error) => {
  //       // Invalid code.
  //     });
  // }

  return (
    <div>
      Recover email not implemented. If you beleive you are seeing this in error
      contact support@etaiq.com
    </div>
  );
};
