import { Getter, PrimitiveAtom, Setter, atom } from "jotai";
import { createAggregatedDollarItemizationAtom } from "./itemization";
import { createAggregatedIterationAtom } from "./iterations";

import { grossRevenueItemization as lbGrossRevenueItemization } from "./lookback";
import { grossRevenueItemization as pjGrossRevenueItemization } from "./projections";

import { costOfGoodsSoldItemization as lbCostOfGoodsSoldItemization } from "./lookback";
import { costOfGoodsSoldItemization as pjCostOfGoodsSoldItemization } from "./projections";

import { operatingExpensesItemization as lbOperatingExpensesItemization } from "./lookback";
import { operatingExpensesItemization as pjOperatingExpensesItemization } from "./projections";

import { newOperatingExpensesItemization as lbNewOperatingExpensesItemization } from "./lookback";

import { getShortId } from "@/utils/id";
import { isNumber } from "@/utils/math";
import { CellTypes, Itemization, ItemizationLineItem } from "./types";

export const isCloningFromEpochsAtom = atom(false);

const clonePairs = [
  [lbGrossRevenueItemization, null, pjGrossRevenueItemization],
  [lbCostOfGoodsSoldItemization, null, pjCostOfGoodsSoldItemization],
  [
    lbOperatingExpensesItemization,
    lbNewOperatingExpensesItemization,
    pjOperatingExpensesItemization,
  ],
] as const;

export const cloneFromEpochsAtom = atom<null, [], void>(null, (get, set) => {
  set(isCloningFromEpochsAtom, true);
  setTimeout(() => {
    for (let i = 0; i < clonePairs.length; i++) {
      cloneItemization(
        get,
        set,
        clonePairs[i][0],
        clonePairs[i][1],
        clonePairs[i][2],
      );
    }
    set(isCloningFromEpochsAtom, false);
  }, 40);
});

// for most itemizations, we can simply clone the last value from the epoch itemization to the first value of the growth itemization

// If OpEx and New OpEx are both itemized in the Historicals: Simply carry it over to the Projection’s OpEx (along with the other OpEx from the Historicals).
// If neither OpEx or New OpEx are itemized in the Historicals: Add the two totals (OpEx + New OpEx), and carry it over to the Projection’s OpEx.
// If OpEx are itemized, but New Operating Expenses are not: Carry over the ‘New OpEx’ total and add it as a single line item in the Projection’s OpEx and call it “New Operating Expenses”.

export const cloneItemization = (
  get: Getter,
  set: Setter,
  epochItemizationAtom: ReturnType<
    typeof createAggregatedDollarItemizationAtom
  >,
  mergeEpochItemizationAtom: ReturnType<
    typeof createAggregatedDollarItemizationAtom
  > | null,
  growthItemizationAtom: ReturnType<typeof createAggregatedIterationAtom>,
) => {
  const consolidatedLastValue =
    get(
      get(get(epochItemizationAtom).consolidatedMolecule).at(
        -1,
      ) as PrimitiveAtom<CellTypes>,
    )?.value || null;

  const mergeConsolidatedLastValue =
    mergeEpochItemizationAtom === null
      ? null
      : get(
          get(get(mergeEpochItemizationAtom).consolidatedMolecule).at(
            -1,
          ) as PrimitiveAtom<CellTypes>,
        )?.value || null;

  const consolidatedValue =
    isNumber(consolidatedLastValue) || isNumber(mergeConsolidatedLastValue)
      ? Number(consolidatedLastValue || 0) +
        Number(mergeConsolidatedLastValue || 0)
      : null;

  const consolidatedFirstAtom = get(
    get(growthItemizationAtom).consolidatedMolecule,
  ).at(0);

  if (!consolidatedFirstAtom) return;

  set(consolidatedFirstAtom, (prev) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { ...prev, value: consolidatedValue as any };
  });

  const epoch = get(get(epochItemizationAtom).itemizationFocusAtom);
  const hasMerge = mergeEpochItemizationAtom !== null;

  const merge: Itemization = hasMerge
    ? get(get(mergeEpochItemizationAtom).itemizationFocusAtom)
    : {
        id: "stub",
        open: false,
        active: false,
        lineItems: [],
      };

  const lineItemsStub: {
    lineItem: ItemizationLineItem;
    value: number;
  }[] = [];

  if (epoch.active) {
    for (let i = 0; i < epoch.lineItems.length; i++) {
      lineItemsStub.push({
        lineItem: {
          ...epoch.lineItems[i],
          id: getShortId(),
        },
        value: get(
          get(get(epochItemizationAtom).lineItemsMolecules[i]).at(
            -1,
          ) as PrimitiveAtom<CellTypes>,
        ).value as number,
      });
    }
  }

  if (merge.active && hasMerge) {
    for (let i = 0; i < merge.lineItems.length; i++) {
      lineItemsStub.push({
        lineItem: {
          ...merge.lineItems[i],
          id: getShortId(),
        },
        value: get(
          get(get(mergeEpochItemizationAtom).lineItemsMolecules[i]).at(
            -1,
          ) as PrimitiveAtom<CellTypes>,
        ).value as number,
      });
    }
  }

  if (hasMerge && epoch.active !== merge.active) {
    lineItemsStub.push({
      lineItem: {
        id: getShortId(),
        label: !epoch.active
          ? "Consolidated Operating Expenses"
          : "New Operating Expenses",
      },
      value:
        Number(
          !epoch.active ? consolidatedLastValue : mergeConsolidatedLastValue,
        ) || 0,
    });
  }

  const growthItemizationValue = get(
    get(growthItemizationAtom).itemizationFocusAtom,
  );

  const growthItemizationNewValue: Itemization = {
    ...growthItemizationValue,
    active: epoch.active || merge.active,
    lineItems: lineItemsStub.map((i) => i.lineItem),
  };

  set(
    get(growthItemizationAtom).itemizationFocusAtom,
    growthItemizationNewValue,
  );

  for (let i = 0; i < lineItemsStub.length; i++) {
    const value = lineItemsStub[i].value;

    set(
      get(get(growthItemizationAtom).lineItemsMolecules[i]).at(
        0,
      ) as PrimitiveAtom<CellTypes>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (c) => ({ ...c, value: value as any }),
    );
  }
};
