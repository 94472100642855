import {
  BigDollarInput,
  BigDollarInputProps,
} from "@/components/BigDollarInput/BigDollarInput";
import { SidebarInputProps } from "./types";

export const InputDollar = ({
  labelWidth = 181,
  ...props
}: SidebarInputProps & BigDollarInputProps) => {
  return (
    <BigDollarInput
      size="sm"
      styles={{
        root: { display: "flex", alignItems: "center" },
        label: { width: labelWidth },
        wrapper: { flex: 1 },
      }}
      {...props}
    />
  );
};
