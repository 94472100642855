import { Route } from "@/paths";
import { Anchor, AnchorProps } from "@mantine/core";
import { Link, To } from "react-router-dom";

export const Logo = ({
  w,
  h,
  style,
  variant,
  to = Route.Home,
  ...props
}: {
  w?: number | string;
  h?: number | string;
  style?: React.CSSProperties;
  variant?: "light" | "dark" | "blue";
  to?: To;
} & AnchorProps) => {
  variant = variant ?? "light";

  const fill =
    {
      light: "var(--mantine-color-white)",
      dark: "var(--mantine-color-brand-outline)",
      blue: "var(--mantine-color-accent-4)",
    }[variant] || "var(--mantine-color-white)";

  return (
    <Anchor
      component={Link}
      to={to}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 196.453 50.631"
        style={{
          aspectRatio: "196.453 / 50.631",
          width: w ?? "auto",
          height: h ?? "auto",
          ...style,
        }}
      >
        <path
          d="m147.408 0-23.531.002v7.016h7.475v36.595h-7.475v7.016h23.531v-7.016h-7.883V7.016h7.883V0zM0 .002v50.629l26.848-.113V43.14l-19.328.111V28.859h19.328v-7.086H7.52V7.381l19.328.223V.227L0 .002zm33.535 0v7.377h11.488v43.25h8.245V7.379h11.207V.002h-30.94zm36.32 0 2.67 7.465h4.334L65.391 50.63h8.244l3.224-12.803h15.612l3.222 12.8h8.246L90.414.003H69.855zm102.172.201c-2.556 0-4.92.384-7.09 1.156a14.545 14.545 0 0 0-5.64 3.618c-1.06 1.06-2.025 2.41-2.893 4.05-.82 1.59-1.47 3.76-1.953 6.508-.434 2.749-.65 5.297-.65 9.781 0 4.484.132 7.23.566 9.979.104.59.215 1.151.334 1.687.436 1.965.975 3.573 1.62 4.823.867 1.59 1.831 2.94 2.892 4.049a14.546 14.546 0 0 0 5.64 3.617c2.17.772 4.534 1.156 7.09 1.156.627 0 1.254-.022 1.881-.07.628-.05 1.17-.078 1.737-.22 2.28-.57 4.492-1.45 6.58-2.53.392-.205.746-.478 1.109-.733-.34-.879-1.144-2.125-.72-2.72.424-.596.594-.3 1.603 1.107 1.007 1.405 3.399 2.892 5.74 5.096l6.58-3.192-8.287-7.119c.498-1.363.901-3.013 1.207-4.951.482-2.749.809-5.495.809-9.979 0-4.484-.242-7.032-.725-9.78-.434-2.75-1.06-4.918-1.88-6.509-.82-1.64-1.785-2.99-2.893-4.05a13.945 13.945 0 0 0-5.569-3.618c-2.17-.772-4.38-1.128-7.088-1.156zM84.63 6.654v.002c.338 0 .53.168.578.506l.578 4.123 4.936 19.6H78.607l4.936-19.6.58-4.123c.048-.338.216-.508.506-.508zm87.398.926c3.037 0 5.4.843 7.088 2.531 1.687 1.64 2.627 3.906 2.82 6.8.097 1.783.17 3.423.217 4.917.193 3.01.11 4.093-.084 7.104-.048 1.493-.12 3.156-.216 4.988-.02.28-.048.557-.084.828l-8.868-7.62-5.972 3.18c3.985 3.449 7.79 7.016 11.61 10.665-2.091 1.89-3.754 2.081-5.51 2.422-.241.048-.482.074-.723.074h-.723c-1.928 0-3.594-.387-4.992-1.158a8.492 8.492 0 0 1-3.252-3.399c-.772-1.446-1.207-3.086-1.303-4.918a300.972 300.972 0 0 1-.361-4.918c-.095-3.06-.011-4.185.084-7.246.096-1.496.215-3.136.36-4.92.193-2.893 1.133-5.158 2.821-6.797 1.687-1.69 4.05-2.533 7.088-2.533zm-58.406 12.658-4.959 4.96 1.854 1.853 2.025 2.025 1.953 1.953 4.961-4.96-5.834-5.83z"
          style={{
            fill,
          }}
        />
      </svg>
    </Anchor>
  );
};
