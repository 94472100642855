import TooltipAmort from "@/docs/tooltips/loan-assumption-sba-7-amortization.mdx";
import TooltipInter from "@/docs/tooltips/loan-assumption-sba-7-interest-rate.mdx";
import {
  sbaLoan7AAmortizationScheduleAtom,
  sbaLoan7AInterestRateAtom,
} from "@/financeModels/liteAtoms";
import { toNumberOrNull } from "@/utils/math";
import { Group, Select } from "@mantine/core";
import { useAtom } from "jotai";
import { RiBankLine } from "react-icons/ri";
import { LabelExplainer } from "../LabelExplainer/LabelExplainer";
import { QuestionnaireSection } from "./QuestionnaireSection";

export const SegmentLoanAssumptionSBA = () => {
  const [sbaLoan7AInterestRate, setSbaLoan7AInterestRate] = useAtom(
    sbaLoan7AInterestRateAtom,
  );
  const [sbaLoan7AAmortizationSchedule, setSbaLoan7AAmortizationSchedule] =
    useAtom(sbaLoan7AAmortizationScheduleAtom);

  const percents = [...Array(11).keys()].map((x) => {
    const val = 0.25 * x + 9.5;

    return {
      value: `${Number((val / 100).toFixed(4)).toString()}`,
      label: `${val.toFixed(2)}%`,
    };
  });

  const years = [10, 25].map((x) => ({
    value: `${x}`,
    label: `${x} years`,
  }));

  return (
    <QuestionnaireSection
      label={"Loan Assumptions: SBA 7(a)"}
      icon={RiBankLine}
    >
      <Group justify="space-evenly" align="center" gap={60} ml={-16}>
        <Select
          withCheckIcon={false}
          allowDeselect={false}
          w={160}
          size="lg"
          data={percents}
          label={
            <LabelExplainer
              label="Interest Rate"
              explanation={<TooltipInter />}
              preventDefault
            />
          }
          value={(toNumberOrNull(sbaLoan7AInterestRate) ?? 0).toString()}
          onChange={(value) => {
            setSbaLoan7AInterestRate(toNumberOrNull(Number(value)));
          }}
        />
        <Select
          withCheckIcon={false}
          allowDeselect={false}
          w={160}
          size="lg"
          data={years}
          label={
            <LabelExplainer
              label="Amortization"
              explanation={<TooltipAmort />}
              preventDefault
            />
          }
          value={(
            toNumberOrNull(sbaLoan7AAmortizationSchedule) ?? 0
          ).toString()}
          onChange={(value) => {
            setSbaLoan7AAmortizationSchedule(toNumberOrNull(Number(value)));
          }}
        />
      </Group>
    </QuestionnaireSection>
  );
};
