import { auth, firestore } from "@/firebase";
import { getDefaultDealTracker } from "@/hooks/useDealTracker";
import { atomWithDocument, authStateAtom } from "@/utils/atomWithFirebase";
import { Timestamp, doc, runTransaction } from "firebase/firestore";
import { atom } from "jotai";
import { Category } from "./category";

export type DealTracker = {
  id: string;
  creatorId: string;
  createdAt: Timestamp;
  status: string;
  categories: Category[];
};

export const getDealTrackerId = () => auth.currentUser?.uid || "unknown";

export const dealTrackerDocumentIdAtom = atom(
  (get) => get(authStateAtom)[0]?.uid || "unknown",
);

export const dealTrackerDocumentAtom = atom(
  (get) =>
    get(
      atomWithDocument<DealTracker>(
        doc(firestore, "dealTracker", get(dealTrackerDocumentIdAtom)),
      ),
    ),
  (get, set, dealTracker: Partial<DealTracker>) =>
    set(
      atomWithDocument<DealTracker>(
        doc(firestore, "dealTracker", get(dealTrackerDocumentIdAtom)),
      ),
      dealTracker,
      { merge: true },
    ),
);

dealTrackerDocumentAtom.onMount = () => {
  runTransaction(firestore, async (transaction) => {
    const docRef = doc(firestore, "dealTracker", getDealTrackerId());
    const docSnap = await transaction.get(docRef);

    if (docSnap.exists()) return;

    transaction.set(docRef, getDefaultDealTracker());
  });
};
