import { dealDocumentAtom } from "@/atoms/deal";
import { customCategoriesAtom } from "@/atoms/dealTracker/category";
import { DealCategoryBadge } from "@/components/DealCategoryBadge/DealCategoryBadge";
import { useUpdateDeal } from "@/hooks/useDeals";
import { Combobox, Pill, PillsInput, useCombobox } from "@mantine/core";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { css } from "vite-plugin-inline-css-modules";

const classes = css`
  .PillsInputField {
    background: "red";
    border: 1px solid red;
  }
`;

export const CategorySelector = () => {
  const categories = useAtomValue(customCategoriesAtom);
  const [deal] = useAtomValue(dealDocumentAtom);
  const selectedCategory = categories.find(
    (item) => item.id === deal?.categoryId,
  );
  const [updateDeal, loading] = useUpdateDeal();

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const [search, setSearch] = useState("");

  const handleValueSelect = (categoryId: string) => {
    updateDeal(deal!.id, { categoryId });
    combobox.closeDropdown();
  };

  const options = categories
    .filter((item) =>
      item.label.toLowerCase().includes(search.trim().toLowerCase()),
    )
    .map((item) => (
      <Combobox.Option
        value={item.id}
        key={item.id}
        active={!!deal?.categoryId && deal?.categoryId.includes(item.id)}
      >
        <DealCategoryBadge size="xl" category={item} />
      </Combobox.Option>
    ));

  if (!selectedCategory) return null;

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      size="lg"
      classNames={classes}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          size="lg"
          onClick={() => combobox.openDropdown()}
          styles={{ input: { padding: "8px 0 0 7px" } }}
        >
          <Pill.Group>
            {!combobox.dropdownOpened && !loading && (
              <DealCategoryBadge size="xl" category={selectedCategory!} />
            )}
            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder=""
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                opacity={combobox.dropdownOpened ? 1 : 0}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found...</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
