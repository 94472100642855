import { auth } from "@/firebase";
import { query, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { Deal, collectionRef } from "./useDeals";

export const useModelDeal = () => {
  const { modelId } = useParams();

  const q =
    !modelId || !auth.currentUser?.uid
      ? null
      : query(
          collectionRef,
          where("modelIds", "array-contains", modelId),
          where("creatorId", "==", auth.currentUser?.uid),
          where("status", "==", "published"),
        );

  const [snapshot, loading, error] = useCollection(q);

  const deal =
    snapshot && snapshot.docs.length > 0
      ? ({ ...snapshot?.docs[0]?.data(), id: snapshot?.docs[0]?.id } as Deal)
      : null;

  return [deal, loading, error] as const;
};
