import { cumulativePrincipalRepaymentAtom } from "@/financeModels/owasco/equity";
import { monospaceFontFamily, textFontFamily } from "@/theme";
import { formatDollar } from "@/utils/format";
import { isNumber } from "@/utils/math";
import { Group, Table, Text } from "@mantine/core";
import { useAtomValue } from "jotai";

export const CumulativePrincipalRepaymentTable = () => {
  const cumulativePrincipalRepayment = useAtomValue(
    cumulativePrincipalRepaymentAtom,
  );

  const items = cumulativePrincipalRepayment;

  return (
    <Table striped withColumnBorders>
      <Table.Thead
        style={{
          position: "sticky",
          top: "0",
          background: "white",
        }}
      >
        <Table.Tr>
          <Table.Th
            ta={"center"}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Cumulative <br />
            Principal Repaid
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody ff={monospaceFontFamily}>
        {items.map((item, i) => {
          const val = item === items[i - 1] ? null : item;

          return (
            <Table.Tr key={i}>
              <Table.Td ta={"right"}>
                {val === null && (
                  <Group w={"100%"} gap={0} justify="center">
                    <Text inherit opacity={0} w={0} c="transparent">
                      placeholder
                    </Text>
                    <Text ff={textFontFamily} fz={12} ta={"center"}>
                      Principal Returned
                    </Text>
                  </Group>
                )}
                {val === 0 && "-"}
                {isNumber(val) && val > 0 && formatDollar(val)}
              </Table.Td>
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  );
};
