import { analytics } from "@/firebase";
import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location]);
};
