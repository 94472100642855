import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  cashFlowAtom,
  purchaseMultipleAtom,
  targetPurchasePriceAtom,
} from "@/financeModels/owasco/general";
import { lazyReactFallback } from "@/utils/lazy";
import { isBetween, toNumberOrNull } from "@/utils/math";
import { Stack } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { TbPigMoney } from "react-icons/tb";
import { ComputedResult } from "./ComputedResult";
import { InputDollar } from "./InputDollar";

const PurchasePriceExplainer = lazyReactFallback(
  () => import("@/docs/sidebar/purchaseInfoPurchasePrice.mdx"),
);

const CashFlowExplainer = lazyReactFallback(
  () => import("@/docs/sidebar/purchaseInfoCashFlow.mdx"),
);

const PurchaseMultipleExplainer = lazyReactFallback(
  () => import("@/docs/sidebar/purchaseInfoPurchaseMultiple.mdx"),
);

export const Panel = () => {
  const [targetPurchasePrice, setTargetPurchasePrice] = useAtom(
    targetPurchasePriceAtom,
  );

  const [cashFlow, setCashflow] = useAtom(cashFlowAtom);

  const purchaseMultiple = useAtomValue(purchaseMultipleAtom);

  return (
    <Stack gap={4}>
      <InputDollar
        label={
          <LabelExplainer
            label="Target Purchase Price"
            explanation={<PurchasePriceExplainer />}
          />
        }
        value={Number(targetPurchasePrice)}
        onChange={(val) => setTargetPurchasePrice(toNumberOrNull(val))}
      />
      <InputDollar
        label={
          <LabelExplainer
            label="Cash Flow / SDE"
            explanation={<CashFlowExplainer />}
          />
        }
        value={Number(cashFlow)}
        onChange={(val) => setCashflow(toNumberOrNull(val))}
      />

      <ComputedResult
        label={
          <LabelExplainer
            label="Listed Purchase Multiple"
            explanation={<PurchaseMultipleExplainer />}
          />
        }
        value={
          isBetween(toNumberOrNull(purchaseMultiple) || 0, 0.01, 99)
            ? Number(toNumberOrNull(purchaseMultiple))
                .toFixed(2)
                .replace(".00", "") + "x"
            : ""
        }
      />
    </Stack>
  );
};

export const ModulePurchase = {
  key: "Purchase Item",
  icon: <TbPigMoney />,
  control: "Purchase Information",
  panel: <Panel />,
};
