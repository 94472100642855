import { LayoutFullscreen } from "@/components/LayoutFullscreen/LayoutFullscreen";
import { auth, prettyPrintFirebaseErrorMessage } from "@/firebase";
import { useCreateDeal } from "@/hooks/useDeals";
import { useCloneModel } from "@/hooks/useModels";
import { useRedirectWhenUserLoads } from "@/hooks/useRedirectIfUserOnPageLoad";
import { Route } from "@/paths";
import {
  Anchor,
  Button,
  Container,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { updateProfile } from "firebase/auth";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { Link, useLocation } from "react-router-dom";

export const PageSignup = () => {
  const location = useLocation();
  const [cloneModel] = useCloneModel();
  const [createDeal] = useCreateDeal();

  useRedirectWhenUserLoads(Route.DealTracker);

  const [createUserWithEmailAndPassword, , loading, error] =
    useCreateUserWithEmailAndPassword(auth, { sendEmailVerification: true });

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
      terms: false,
    },

    validate: {
      email: (val) =>
        /^\S+@\S+$/.test(val) ? null : "Email format is invalid",
      password: (val) =>
        val.length <= 6
          ? "Password should include at least 6 characters"
          : null,
    },
  });

  const handleSubmit = async () => {
    const result = await createUserWithEmailAndPassword(
      form.values.email,
      form.values.password,
    );

    if (!result || !result.user) return;

    await updateProfile(result.user, { displayName: form.values.name });

    if (!location.state?.cloneModelId) return;

    const c = await cloneModel(location.state.cloneModelId, {
      name: "Homepage Model",
    });
    if (!c) return;
    await createDeal({ modelIds: [c.id] });
  };

  return (
    <LayoutFullscreen>
      <Container size={460} my={40} pb={"xl"}>
        <Text size="xl" fz={40}>
          Let's get you set up
        </Text>

        {location.state?.cloneModelId ? (
          <Text c="dimmed" size="sm" mt={5}>
            Create an account to keep working on your model.
          </Text>
        ) : (
          <Text c="dimmed" size="sm" mt={5}>
            If you are already using ETA IQ, sign in{" "}
            <Anchor
              size="sm"
              component={Link}
              to={{
                pathname: Route.Login,
                //state: { returnTo: location.state?.returnTo },
              }}
            >
              here.
            </Anchor>
          </Text>
        )}

        <Paper radius={0} withBorder shadow="md" p={30} mt={30}>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
              <TextInput
                label="Name"
                placeholder="Your name"
                value={form.values.name}
                onChange={(event) =>
                  form.setFieldValue("name", event.currentTarget.value)
                }
                size="md"
              />
              <TextInput
                required
                label="Email"
                placeholder="hello@example.com"
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
                error={form.errors.email}
                size="md"
              />
              <PasswordInput
                required
                label="Password"
                placeholder="Your password"
                value={form.values.password}
                onChange={(event) =>
                  form.setFieldValue("password", event.currentTarget.value)
                }
                error={form.errors.password}
                size="md"
              />

              <Button type="submit" loading={!!loading} size="md">
                Get Started
              </Button>

              <Text c="gray.6" size="sm" ta="center">
                By creating an account I agree with <br /> the{" "}
                <Anchor component={Link} to={Route.TermOfService}>
                  Terms of Services
                </Anchor>{" "}
                and{" "}
                <Anchor component={Link} to={Route.TermOfService}>
                  Privacy Policy
                </Anchor>
              </Text>

              {error && (
                <Text c="red" ta="center" mt={10}>
                  {prettyPrintFirebaseErrorMessage(error.message)}
                </Text>
              )}
            </Stack>
          </form>
        </Paper>
      </Container>
    </LayoutFullscreen>
  );
};
