import { getShortId } from "@/utils/id";
import { atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { atomFamily } from "jotai/utils";
import { modelAtom } from "./model";
import { Epoch } from "./types";

export const epochsFocusAtom = focusAtom(modelAtom, (optic) =>
  optic.prop("epochs"),
);

const epochsAtomCache = atomFamily((epochId: string) =>
  focusAtom(epochsFocusAtom, (optic) => optic.prop(epochId)),
);

export const orderedEpochsMolecule = atom((get) => {
  const epochsMap = get(epochsFocusAtom);
  return Object.values(epochsMap)
    .sort((a, b) => a.year - b.year)
    .map((epoch) => epochsAtomCache(epoch.id));
});

export const createEpochAtom = atom(
  null,
  (get, set, epoch?: Omit<Epoch, "id">) => {
    set(modelAtom, (prev) => {
      const epochId = getShortId();
      const epochAtoms = get(orderedEpochsMolecule);

      const year =
        epochAtoms.length > 0
          ? get(epochAtoms[epochAtoms.length - 1]).year + 1
          : new Date().getFullYear();

      const newEpoch: Epoch = {
        id: epochId,
        year,
        type: "full",
        ...epoch,
      };
      const newEpochs = {
        ...prev.epochs,
        [epochId]: newEpoch,
      };
      return { ...prev, epochs: newEpochs };
    });
  },
);

export const deleteEpochAtom = atom(null, (_get, set, epochId: string) => {
  set(modelAtom, (prev) => {
    const newEpochs = { ...prev.epochs };
    delete newEpochs[epochId];
    return { ...prev, epochs: newEpochs };
  });
});
