import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { useableFreeCashFlowMolecule } from "@/financeModels/owasco/projections";
import { Group, ScrollArea, Stack, Text } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import { useState } from "react";
import { CellRow } from "../CellGrid/CellRow";
import { CashOnReturnTable } from "./CashOnReturnTable";
import { CumulativePrincipalRepaymentTable } from "./CumulativePrincipalRepaymentTable";
import { IndividualInvestorReturnsTable } from "./IndividualInvestorReturnsTable";
import { InvestorDistributionsTable } from "./InvestorDistributionsTable";
import { IndividualInvestorReturnInput } from "./InvidualInvestorReturnInput";
import { ReturnMetricsTable } from "./RetrunMetricsTable";

export const PanelROI = () => {
  // This panel requires some calcs from the useableFreeCashFlowMolecule
  // otherwise it loads slow, loading them first before showing the panel
  // it will use caches instead of recalculating

  const [show, setShow] = useState(false);

  useTimeout(
    () => {
      setShow(true);
    },
    100,
    {
      autoInvoke: true,
    },
  );

  return (
    <>
      <div style={{ display: "none" }}>
        <CellRow
          label="Return Metrics"
          tabY={0}
          molecule={useableFreeCashFlowMolecule}
        />
      </div>
      <ScrollArea w="100%" offsetScrollbars flex={1} h={"calc(100% - 20px)"}>
        {show && (
          <Stack
            gap={"xs"}
            flex={1}
            h="100%"
            style={{ overflow: "hidden" }}
            pb={"40vh"}
          >
            <Text size="lg" fw={"bold"}>
              Cap Table & Return Metrics
            </Text>

            <Group wrap="nowrap" align="stretch">
              <ReturnMetricsTable />
            </Group>
            <Group
              justify="space-between"
              align="flex-end"
              w={500}
              pos="relative"
              top={100 - 30}
              h={0}
            >
              <Text size="lg" fw={"bold"}>
                Investor Distributions
              </Text>
            </Group>

            <Group wrap="nowrap" align="stretch" pt={100}>
              <LayoutWorkstation.Panel p={0} miw={940}>
                <InvestorDistributionsTable />
              </LayoutWorkstation.Panel>
              <LayoutWorkstation.Panel p={0}>
                <CumulativePrincipalRepaymentTable />
              </LayoutWorkstation.Panel>
              <LayoutWorkstation.Panel p={0}>
                <CashOnReturnTable />
              </LayoutWorkstation.Panel>
              <Stack gap={0} pos={"relative"}>
                <LayoutWorkstation.Panel p={0}>
                  <IndividualInvestorReturnsTable />
                </LayoutWorkstation.Panel>
                <IndividualInvestorReturnInput />
              </Stack>
            </Group>
          </Stack>
        )}
      </ScrollArea>
    </>
  );
};
