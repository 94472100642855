import { Getter, atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { createStorableCellAtom } from "./cell";
import { orderedEpochsMolecule } from "./epoch";
import { CellTypes, DollarCell, Epoch } from "./types";

export const createStorableCellMoleculeFromEpochs = (
  id: string,
  fn: (epoch: Epoch, index: number, get: Getter) => CellTypes,
) =>
  atom((get) => {
    const epochs = get(orderedEpochsMolecule);
    return epochs.map((epoch, index) =>
      createStorableCellAtom(
        `epoch|${get(epoch).id}|cell|${id}`,
        fn(get(epoch), index, get),
      ),
    );
  });

export const createComputedCellMoleculeFromEpochs = (
  fn: (get: Getter, index: number, epoch: Epoch) => CellTypes,
) => {
  const cache = atomFamily(
    ({ epoch, index }: { epoch: Epoch; index: number }) =>
      atom((get) => fn(get, index, epoch)),
    (a, b) => a.index === b.index,
  );

  return atom((get) =>
    get(orderedEpochsMolecule).map((epoch, index) =>
      cache({ epoch: get(epoch), index }),
    ),
  );
};

export const createStorableDollarMoleculeFromEpochs = (
  id: string,
  options?: { value?: number; isNegative?: boolean },
) => {
  return createStorableCellMoleculeFromEpochs(id, () => {
    return {
      id,
      type: "dollar",
      value: options?.value ?? null,
      isNegative: options?.isNegative || false,
    } as DollarCell;
  });
};
