import { dealTrackerDocumentAtom } from "@/atoms/dealTracker/dealTracker";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { useAtomValue } from "jotai";
import { Main } from "./Main/Main";
import { Sidebar } from "./Sidebar/Sidebar";

export const PageDealTracker = () => {
  useAtomValue(dealTrackerDocumentAtom);

  return (
    <LayoutWorkstation>
      <Sidebar />
      <Main />
    </LayoutWorkstation>
  );
};
