import { getAuth, User } from "firebase/auth";
import {
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot,
  Query,
  QuerySnapshot,
  setDoc,
  SetOptions,
} from "firebase/firestore";
import { Atom, atom, WritableAtom } from "jotai";

export const authStateAtom = atom<[User | null, boolean, Error | undefined]>([
  null,
  true,
  undefined,
]);

authStateAtom.onMount = (setAtom) => {
  const auth = getAuth();
  auth.onAuthStateChanged(
    async (user) => {
      setAtom([user, false, undefined]);
    },
    (error) => {
      setAtom([null, false, error]);
    },
  );
};

type SnapshotResult<T, SnapshotT> = [
  T | undefined,
  boolean,
  FirestoreError | undefined,
  SnapshotT | undefined,
];

const cache = new Map<
  string,
  WritableAtom<
    [
      unknown | undefined,
      boolean,
      FirestoreError | undefined,
      DocumentSnapshot | undefined,
    ],
    [data: Partial<unknown>, options: SetOptions],
    void
  >
>();

export function atomWithDocument<T>(
  docRef: DocumentReference,
): WritableAtom<
  SnapshotResult<T, DocumentSnapshot>,
  [data: Partial<T>, options?: SetOptions],
  void
> {
  if (cache.has(docRef.path)) {
    return cache.get(docRef.path)! as WritableAtom<
      SnapshotResult<T, DocumentSnapshot>,
      [data: Partial<T>, options?: SetOptions],
      void
    >;
  }

  const dataAtom = atom<SnapshotResult<T, DocumentSnapshot>>([
    undefined,
    true,
    undefined,
    undefined,
  ]);

  dataAtom.onMount = (dispatch) => {
    return onSnapshot(
      docRef,
      (snapshot) => {
        dispatch([
          { ...snapshot.data(), id: snapshot.id } as T,
          false,
          undefined,
          snapshot,
        ]);
      },
      (error) => {
        dispatch([undefined, false, error, undefined]);
      },
    );
  };

  const resultAtom = atom(
    (get) => {
      return get(dataAtom);
    },
    (_get, _set, data: Partial<T>, options?: SetOptions) => {
      setDoc(docRef, data as Partial<unknown>, { ...options });
    },
  );

  cache.set(docRef.path, resultAtom);

  return resultAtom;
}

export function atomWithQuery<T>(
  query: Query,
): Atom<SnapshotResult<T[], QuerySnapshot>> {
  const dataAtom = atom<SnapshotResult<T[], QuerySnapshot>>([
    undefined,
    true,
    undefined,
    undefined,
  ]);

  dataAtom.onMount = (dispatch) => {
    return onSnapshot(
      query,
      (snapshot) => {
        dispatch([
          snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() } as T;
          }),
          false,
          undefined,
          snapshot,
        ]);
      },
      (error) => {
        dispatch([undefined, false, error, undefined]);
      },
    );
  };

  return atom((get) => {
    return get(dataAtom);
  });
}
