import {
  Anchor,
  Box,
  Button,
  Container,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core";

import { auth, prettyPrintFirebaseErrorMessage } from "@/firebase";
import { Route } from "@/paths";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { IoArrowBackSharp } from "react-icons/io5";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { Link } from "react-router-dom";
import { LayoutFullscreen } from "../LayoutFullscreen/LayoutFullscreen";

export const PageForgotPassword = () => {
  const [mailSent, setMailSent] = useState(false);
  const form = useForm({
    initialValues: {
      email: "",
    },
  });

  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);

  const handleSubmit = async () => {
    await sendPasswordResetEmail(form.values.email);
    setMailSent(true);
  };

  if (mailSent && !error) {
    return (
      <LayoutFullscreen>
        <Container mb={60}>
          <Paper withBorder shadow="md" p={30} mt={30} maw={400}>
            <Stack align="center">
              <MdOutlineMarkEmailRead size={80} />

              <Text fz={30} ta="center">
                Check your email.
              </Text>
              <Text c="dimmed" fz="md" ta="center">
                If you have an account with us, you will receive an email with a
                link to reset your password.
              </Text>
            </Stack>
          </Paper>
        </Container>
      </LayoutFullscreen>
    );
  }

  return (
    <LayoutFullscreen>
      <Container mb={60}>
        <Text fz={32} ta="center">
          Forgot your password?
        </Text>
        <Text c="dimmed" fz="sm" ta="center">
          Enter your email to get a reset link
        </Text>

        <Paper withBorder shadow="md" p={30} radius="md" my="xl">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
              <TextInput
                label="Your email"
                placeholder="hello@example.com"
                required
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
              />
              <Button type="submit" loading={sending}>
                Reset password
              </Button>
              {error && (
                <Text c="red" mt="xl" ta="center">
                  {prettyPrintFirebaseErrorMessage(error.message)}
                </Text>
              )}{" "}
              <Anchor
                c="dimmed"
                size="sm"
                mr={30}
                component={Link}
                to={Route.Login}
              >
                <Group gap={0}>
                  <IoArrowBackSharp
                    style={{ width: rem(12), height: rem(12) }}
                  />
                  <Box ml={5}>Back to login page</Box>
                </Group>
              </Anchor>
            </Stack>
          </form>
        </Paper>
      </Container>
    </LayoutFullscreen>
  );
};
