import {
  cashFlowAtom,
  equityPercentAtom,
  sbaLoan7AAmortizationScheduleAtom,
  sbaLoan7AInterestRateAtom,
  sbaLoanPercentAtom,
  sellersNoteAmortizationScheduleAtom,
  sellersNoteInterestRateAtom,
  sellersNotePercentAtom,
  targetPurchasePriceAtom,
} from "@/financeModels/liteAtoms";
import { Route } from "@/paths";
import { useAtom, useAtomValue } from "jotai";
import { generatePath, useNavigate } from "react-router-dom";
import { useModel } from "./useModel";
import { useCreateModel } from "./useModels";

export const AnonymousCreatorId = "anonymous";

export const useLiteModelToFull = () => {
  const navigate = useNavigate();
  const [create, loading, error] = useCreateModel();

  const targetPurchasePrice = useAtomValue(targetPurchasePriceAtom);
  const cashFlow = useAtomValue(cashFlowAtom);

  const sbaLoanPercent = useAtomValue(sbaLoanPercentAtom);
  const sellersNotePercent = useAtomValue(sellersNotePercentAtom);
  const equityPercent = useAtomValue(equityPercentAtom);

  const sbaLoan7AInterestRate = useAtomValue(sbaLoan7AInterestRateAtom);
  const sbaLoan7AAmortizationSchedule = useAtomValue(
    sbaLoan7AAmortizationScheduleAtom,
  );

  const sellersNoteInterestRate = useAtomValue(sellersNoteInterestRateAtom);
  const sellersNoteAmortizationSchedule = useAtom(
    sellersNoteAmortizationScheduleAtom,
  );

  const fullModel = {
    general: {
      targetPurchasePrice: Number(targetPurchasePrice) || 0,
      cashFlow: Number(cashFlow) || 0,

      sbaLoanPercent: Number(sbaLoanPercent) || 0.8,
      sellersNotePercent: Number(sellersNotePercent) || 0.1,
      equityPercent: Number(equityPercent) || 0.1,

      sbaLoanInterestRate: Number(sbaLoan7AInterestRate) || 0.05,
      UNUSEDED_FIX_ME_sbaLoan7AAmortizationSchedule:
        Number(sbaLoan7AAmortizationSchedule) || 10,

      sellersNoteInterestRate: Number(sellersNoteInterestRate) || 0.05,
      sellersNoteAmortizationSchedule:
        Number(sellersNoteAmortizationSchedule) || 10,
    },
  };

  const createModel = async () => {
    const s = await create(
      {
        creatorId: AnonymousCreatorId,
      },
      fullModel,
    );

    navigate(
      generatePath(Route.ModelTab, {
        modelTabId: "historicals",
        modelId: s!.id,
      }),
    );
  };

  return { createModel, fullModel, loading, error };
};

export const useIsModelAnonymous = () => {
  const [model] = useModel();
  return model?.creatorId === AnonymousCreatorId;
};
