import { MultipleCell as MultipleCellType } from "@/financeModels/owasco/types";
import { isNumber, toNumberOrNull } from "@/utils/math";
import { NumberInput } from "@mantine/core";
import { PrimitiveAtom, useAtom } from "jotai";
import React, { memo, useState } from "react";
import { Stub } from "./cell/Stub";
import { Wrapper } from "./cell/Wrapper";
import { useTabCoordinates } from "./hooks/useTabCoordinates";

export type MultipleCellProps = {
  tabX: number;
  tabY: number;
  atom: PrimitiveAtom<MultipleCellType>;
  ta?: "left" | "right" | "center";
  w?: number;
  h?: number;
  style?: React.CSSProperties;
  justifyContent?: "left" | "right" | "center";
  tooltip?: string;
};

export const MultipleCell = memo(
  ({
    tabX,
    tabY,
    atom,
    ta,
    w,
    h,
    justifyContent = "left",
    style = {},
  }: MultipleCellProps) => {
    const [cell, setCell] = useAtom(atom);
    const [inputValue, setInputValue] = useState(cell.value);
    const [isEditing, setIsEditing] = useState(false);

    const { id, moveFocus } = useTabCoordinates({ tabX, tabY });

    const { isLocked, isOverrided, isBlank } = cell;
    const isEditMode = !isLocked && isEditing;

    const resetCell = () => {
      setCell((c) => ({ ...c, value: null }));
    };

    return (
      <Wrapper w={w} h={h} id={id} style={style}>
        {!isEditMode && (
          <Stub
            resetCell={resetCell}
            ta={ta}
            moveFocus={moveFocus}
            label={
              isNumber(cell.value)
                ? `${toNumberOrNull(cell.value)?.toFixed(2)}x`
                : ""
            }
            enableEditing={() => {
              setInputValue(null);
              setIsEditing(true);
            }}
            locked={isLocked}
            blank={isBlank}
            overrided={isOverrided}
            animationKey={cell.value?.toString()}
            justifyContent={justifyContent}
          />
        )}
        {isEditMode && (
          <NumberInput
            value={toNumberOrNull(inputValue) || 0}
            onChange={(value) => setInputValue(toNumberOrNull(value) || 0)}
            autoFocus
            radius={0}
            size="sm"
            w={"100%"}
            rightSection="%"
            min={0}
            step={0.01}
            hideControls
            onBlur={() => {
              setIsEditing(false);
              setCell((c) => ({ ...c, value: inputValue }));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setCell((c) => ({ ...c, value: inputValue }));
                setIsEditing(false);
                moveFocus("down");
              }
            }}
          />
        )}
      </Wrapper>
    );
  },
);
