//////
// This file was created using the buildDocManifest.js file 
// in the scripts directory. Do not modify this file directly.
//
// npm run build:docManifest
//////

import { lazyReactFallback } from "@/utils/lazy";
export const manifest = {
  "@/docs/about-us.mdx": lazyReactFallback(
    () => import("@/docs/about-us.mdx")
  ),
  "@/docs/content/roiProjectedReturnforIndividualInvestor.mdx": lazyReactFallback(
    () => import("@/docs/content/roiProjectedReturnforIndividualInvestor.mdx")
  ),
  "@/docs/kitchen-sink.mdx": lazyReactFallback(
    () => import("@/docs/kitchen-sink.mdx")
  ),
  "@/docs/learn/educate/how-to-care-for-your-dog.mdx": lazyReactFallback(
    () => import("@/docs/learn/educate/how-to-care-for-your-dog.mdx")
  ),
  "@/docs/learn/test.mdx": lazyReactFallback(
    () => import("@/docs/learn/test.mdx")
  ),
  "@/docs/line-items/formulas/gross-profit.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/gross-profit.mdx")
  ),
  "@/docs/line-items/formulas/historicals-buyers-adj-ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-buyers-adj-ebitda-margins.mdx")
  ),
  "@/docs/line-items/formulas/historicals-buyers-adj-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-buyers-adj-ebitda.mdx")
  ),
  "@/docs/line-items/formulas/historicals-distributable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-distributable-free-cash-flow.mdx")
  ),
  "@/docs/line-items/formulas/historicals-equity-investor-distributions.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-equity-investor-distributions.mdx")
  ),
  "@/docs/line-items/formulas/historicals-income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-income-taxes.mdx")
  ),
  "@/docs/line-items/formulas/historicals-line-of-credit-total.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-line-of-credit-total.mdx")
  ),
  "@/docs/line-items/formulas/historicals-net-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-net-income.mdx")
  ),
  "@/docs/line-items/formulas/historicals-sba7a-loan-total.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-sba7a-loan-total.mdx")
  ),
  "@/docs/line-items/formulas/historicals-sellers-adj-ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-sellers-adj-ebitda-margins.mdx")
  ),
  "@/docs/line-items/formulas/historicals-sellers-adj-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-sellers-adj-ebitda.mdx")
  ),
  "@/docs/line-items/formulas/historicals-sellers-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-sellers-ebitda.mdx")
  ),
  "@/docs/line-items/formulas/historicals-sellers-note-total.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-sellers-note-total.mdx")
  ),
  "@/docs/line-items/formulas/historicals-taxable-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-taxable-income.mdx")
  ),
  "@/docs/line-items/formulas/historicals-total-debt-service.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-total-debt-service.mdx")
  ),
  "@/docs/line-items/formulas/historicals-unlevered-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-unlevered-free-cash-flow.mdx")
  ),
  "@/docs/line-items/formulas/historicals-usable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/historicals-usable-free-cash-flow.mdx")
  ),
  "@/docs/line-items/formulas/projections-distributable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-distributable-free-cash-flow.mdx")
  ),
  "@/docs/line-items/formulas/projections-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-ebitda.mdx")
  ),
  "@/docs/line-items/formulas/projections-equity-investor-distributions.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-equity-investor-distributions.mdx")
  ),
  "@/docs/line-items/formulas/projections-income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-income-taxes.mdx")
  ),
  "@/docs/line-items/formulas/projections-line-of-credit-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-line-of-credit-total-payments.mdx")
  ),
  "@/docs/line-items/formulas/projections-net-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-net-income.mdx")
  ),
  "@/docs/line-items/formulas/projections-operating-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-operating-income.mdx")
  ),
  "@/docs/line-items/formulas/projections-sba7a-loan-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-sba7a-loan-total-payments.mdx")
  ),
  "@/docs/line-items/formulas/projections-sellers-note-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-sellers-note-total-payments.mdx")
  ),
  "@/docs/line-items/formulas/projections-taxable-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-taxable-income.mdx")
  ),
  "@/docs/line-items/formulas/projections-total-debt-service.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-total-debt-service.mdx")
  ),
  "@/docs/line-items/formulas/projections-unlevered-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-unlevered-free-cash-flow.mdx")
  ),
  "@/docs/line-items/formulas/projections-usable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/formulas/projections-usable-free-cash-flow.mdx")
  ),
  "@/docs/line-items/historicals/amortization-of-deal-expenses.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/amortization-of-deal-expenses.mdx")
  ),
  "@/docs/line-items/historicals/annual-maintenance-capex.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/annual-maintenance-capex.mdx")
  ),
  "@/docs/line-items/historicals/buyers-adj-ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/buyers-adj-ebitda-margins.mdx")
  ),
  "@/docs/line-items/historicals/buyers-adj-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/buyers-adj-ebitda.mdx")
  ),
  "@/docs/line-items/historicals/buyers-compensation.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/buyers-compensation.mdx")
  ),
  "@/docs/line-items/historicals/buyers-purchase-multiple.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/buyers-purchase-multiple.mdx")
  ),
  "@/docs/line-items/historicals/companys-tax-rate.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/companys-tax-rate.mdx")
  ),
  "@/docs/line-items/historicals/cost-of-goods-sold.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/cost-of-goods-sold.mdx")
  ),
  "@/docs/line-items/historicals/depreciation-and-amortization.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/depreciation-and-amortization.mdx")
  ),
  "@/docs/line-items/historicals/distributable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/distributable-free-cash-flow.mdx")
  ),
  "@/docs/line-items/historicals/equity-investor-distributions.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/equity-investor-distributions.mdx")
  ),
  "@/docs/line-items/historicals/equity-investor-prefered-dividends.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/equity-investor-prefered-dividends.mdx")
  ),
  "@/docs/line-items/historicals/equity-investor-principal-repayments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/equity-investor-principal-repayments.mdx")
  ),
  "@/docs/line-items/historicals/equity-investor-profit-distributions.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/equity-investor-profit-distributions.mdx")
  ),
  "@/docs/line-items/historicals/gross-margin.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/gross-margin.mdx")
  ),
  "@/docs/line-items/historicals/gross-profit.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/gross-profit.mdx")
  ),
  "@/docs/line-items/historicals/gross-revenue.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/gross-revenue.mdx")
  ),
  "@/docs/line-items/historicals/income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/income-taxes.mdx")
  ),
  "@/docs/line-items/historicals/line-of-credit-interest.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/line-of-credit-interest.mdx")
  ),
  "@/docs/line-items/historicals/line-of-credit-principal.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/line-of-credit-principal.mdx")
  ),
  "@/docs/line-items/historicals/line-of-credit-total.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/line-of-credit-total.mdx")
  ),
  "@/docs/line-items/historicals/net-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/net-income.mdx")
  ),
  "@/docs/line-items/historicals/new-operating-expenses.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/new-operating-expenses.mdx")
  ),
  "@/docs/line-items/historicals/operating-expenses.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/operating-expenses.mdx")
  ),
  "@/docs/line-items/historicals/other-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/other-income.mdx")
  ),
  "@/docs/line-items/historicals/sba-7a-loan-interest.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sba-7a-loan-interest.mdx")
  ),
  "@/docs/line-items/historicals/sba-7a-loan-principal.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sba-7a-loan-principal.mdx")
  ),
  "@/docs/line-items/historicals/sba7a-loan-total.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sba7a-loan-total.mdx")
  ),
  "@/docs/line-items/historicals/sellers-addbacks.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-addbacks.mdx")
  ),
  "@/docs/line-items/historicals/sellers-adj-ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-adj-ebitda-margins.mdx")
  ),
  "@/docs/line-items/historicals/sellers-adj-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-adj-ebitda.mdx")
  ),
  "@/docs/line-items/historicals/sellers-depreciation-amortization.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-depreciation-amortization.mdx")
  ),
  "@/docs/line-items/historicals/sellers-ebitda.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-ebitda.mdx")
  ),
  "@/docs/line-items/historicals/sellers-income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-income-taxes.mdx")
  ),
  "@/docs/line-items/historicals/sellers-interest-expenses.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-interest-expenses.mdx")
  ),
  "@/docs/line-items/historicals/sellers-note-interest.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-note-interest.mdx")
  ),
  "@/docs/line-items/historicals/sellers-note-principal.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-note-principal.mdx")
  ),
  "@/docs/line-items/historicals/sellers-note-total.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/sellers-note-total.mdx")
  ),
  "@/docs/line-items/historicals/taxable-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/taxable-income.mdx")
  ),
  "@/docs/line-items/historicals/total-debt-service.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/total-debt-service.mdx")
  ),
  "@/docs/line-items/historicals/unlevered-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/unlevered-free-cash-flow.mdx")
  ),
  "@/docs/line-items/historicals/usable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/historicals/usable-free-cash-flow.mdx")
  ),
  "@/docs/line-items/projections/amortization-of-deal-expenses.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/amortization-of-deal-expenses.mdx")
  ),
  "@/docs/line-items/projections/annual-maintenance-capex.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/annual-maintenance-capex.mdx")
  ),
  "@/docs/line-items/projections/buyers-compensation.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/buyers-compensation.mdx")
  ),
  "@/docs/line-items/projections/companys-tax-rates.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/companys-tax-rates.mdx")
  ),
  "@/docs/line-items/projections/cost-of-goods-sold.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/cost-of-goods-sold.mdx")
  ),
  "@/docs/line-items/projections/depreciation-and-amortization.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/depreciation-and-amortization.mdx")
  ),
  "@/docs/line-items/projections/distributable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/distributable-free-cash-flow.mdx")
  ),
  "@/docs/line-items/projections/ebitda-margins.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/ebitda-margins.mdx")
  ),
  "@/docs/line-items/projections/ebitda.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/ebitda.mdx")
  ),
  "@/docs/line-items/projections/equity-investor-distributions.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/equity-investor-distributions.mdx")
  ),
  "@/docs/line-items/projections/equity-investor-prefered-dividends.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/equity-investor-prefered-dividends.mdx")
  ),
  "@/docs/line-items/projections/equity-investor-principal-repayments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/equity-investor-principal-repayments.mdx")
  ),
  "@/docs/line-items/projections/equity-investor-profit-distributions.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/equity-investor-profit-distributions.mdx")
  ),
  "@/docs/line-items/projections/gross-margins.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/gross-margins.mdx")
  ),
  "@/docs/line-items/projections/gross-profit.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/gross-profit.mdx")
  ),
  "@/docs/line-items/projections/gross-revenue-yoy-growth.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/gross-revenue-yoy-growth.mdx")
  ),
  "@/docs/line-items/projections/gross-revenue.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/gross-revenue.mdx")
  ),
  "@/docs/line-items/projections/income-taxes.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/income-taxes.mdx")
  ),
  "@/docs/line-items/projections/line-of-credit-interest.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/line-of-credit-interest.mdx")
  ),
  "@/docs/line-items/projections/line-of-credit-principal.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/line-of-credit-principal.mdx")
  ),
  "@/docs/line-items/projections/line-of-credit-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/line-of-credit-total-payments.mdx")
  ),
  "@/docs/line-items/projections/net-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/net-income.mdx")
  ),
  "@/docs/line-items/projections/operating-expenses.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/operating-expenses.mdx")
  ),
  "@/docs/line-items/projections/operating-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/operating-income.mdx")
  ),
  "@/docs/line-items/projections/proceeds-from-company-sale.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/proceeds-from-company-sale.mdx")
  ),
  "@/docs/line-items/projections/sba-7a-loan-extra-payments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/sba-7a-loan-extra-payments.mdx")
  ),
  "@/docs/line-items/projections/sba-7a-loan-interest.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/sba-7a-loan-interest.mdx")
  ),
  "@/docs/line-items/projections/sba-7a-loan-principal.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/sba-7a-loan-principal.mdx")
  ),
  "@/docs/line-items/projections/sba7a-loan-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/sba7a-loan-total-payments.mdx")
  ),
  "@/docs/line-items/projections/sellers-note-extra-payments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/sellers-note-extra-payments.mdx")
  ),
  "@/docs/line-items/projections/sellers-note-interest.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/sellers-note-interest.mdx")
  ),
  "@/docs/line-items/projections/sellers-note-principal.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/sellers-note-principal.mdx")
  ),
  "@/docs/line-items/projections/sellers-note-total-payments.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/sellers-note-total-payments.mdx")
  ),
  "@/docs/line-items/projections/taxable-income.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/taxable-income.mdx")
  ),
  "@/docs/line-items/projections/total-debt-service.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/total-debt-service.mdx")
  ),
  "@/docs/line-items/projections/unlevered-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/unlevered-free-cash-flow.mdx")
  ),
  "@/docs/line-items/projections/usable-free-cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/line-items/projections/usable-free-cash-flow.mdx")
  ),
  "@/docs/pills/historicals/buyerssAdjustedEBIDTA.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/buyerssAdjustedEBIDTA.mdx")
  ),
  "@/docs/pills/historicals/distributableFreeCashflow.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/distributableFreeCashflow.mdx")
  ),
  "@/docs/pills/historicals/equityInvestorDistributions.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/equityInvestorDistributions.mdx")
  ),
  "@/docs/pills/historicals/incomeTaxes.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/incomeTaxes.mdx")
  ),
  "@/docs/pills/historicals/lineOfCredit.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/lineOfCredit.mdx")
  ),
  "@/docs/pills/historicals/sba7aLoanTotal.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/sba7aLoanTotal.mdx")
  ),
  "@/docs/pills/historicals/sellersAdjustedEBITDA.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/sellersAdjustedEBITDA.mdx")
  ),
  "@/docs/pills/historicals/sellersNoteTotal.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/sellersNoteTotal.mdx")
  ),
  "@/docs/pills/historicals/taxableIncome.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/taxableIncome.mdx")
  ),
  "@/docs/pills/historicals/totalDebtService.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/totalDebtService.mdx")
  ),
  "@/docs/pills/historicals/unleveredFreeCashFlow.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/unleveredFreeCashFlow.mdx")
  ),
  "@/docs/pills/historicals/useableFreeCashFlow.mdx": lazyReactFallback(
    () => import("@/docs/pills/historicals/useableFreeCashFlow.mdx")
  ),
  "@/docs/pills/projections/ebitda.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/ebitda.mdx")
  ),
  "@/docs/pills/projections/equityInvestorDistributions.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/equityInvestorDistributions.mdx")
  ),
  "@/docs/pills/projections/grossProfit.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/grossProfit.mdx")
  ),
  "@/docs/pills/projections/incomeTaxes.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/incomeTaxes.mdx")
  ),
  "@/docs/pills/projections/lineOfCredit.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/lineOfCredit.mdx")
  ),
  "@/docs/pills/projections/netIncome.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/netIncome.mdx")
  ),
  "@/docs/pills/projections/operatingIncome.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/operatingIncome.mdx")
  ),
  "@/docs/pills/projections/sellersNote.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/sellersNote.mdx")
  ),
  "@/docs/pills/projections/seniorDebt.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/seniorDebt.mdx")
  ),
  "@/docs/pills/projections/taxableIncome.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/taxableIncome.mdx")
  ),
  "@/docs/pills/projections/totalDebtService.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/totalDebtService.mdx")
  ),
  "@/docs/pills/projections/unleveredFreeCashFlow.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/unleveredFreeCashFlow.mdx")
  ),
  "@/docs/pills/projections/useableFreeCashFlow.mdx": lazyReactFallback(
    () => import("@/docs/pills/projections/useableFreeCashFlow.mdx")
  ),
  "@/docs/privacy-policy.mdx": lazyReactFallback(
    () => import("@/docs/privacy-policy.mdx")
  ),
  "@/docs/sidebar/buyersCompAnnualBaseSallary.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/buyersCompAnnualBaseSallary.mdx")
  ),
  "@/docs/sidebar/buyersCompHealthBenefits.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/buyersCompHealthBenefits.mdx")
  ),
  "@/docs/sidebar/buyersCompPayrollTaxes.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/buyersCompPayrollTaxes.mdx")
  ),
  "@/docs/sidebar/companySaleExitMultiple.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/companySaleExitMultiple.mdx")
  ),
  "@/docs/sidebar/companySaleIncluded.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/companySaleIncluded.mdx")
  ),
  "@/docs/sidebar/companySaleYear.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/companySaleYear.mdx")
  ),
  "@/docs/sidebar/dealExpensesLegalExpenses.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/dealExpensesLegalExpenses.mdx")
  ),
  "@/docs/sidebar/dealExpensesOtherDealExpenses.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/dealExpensesOtherDealExpenses.mdx")
  ),
  "@/docs/sidebar/dealExpensesQualityOfEarnings.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/dealExpensesQualityOfEarnings.mdx")
  ),
  "@/docs/sidebar/dealExpensesTotalDealExpenses.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/dealExpensesTotalDealExpenses.mdx")
  ),
  "@/docs/sidebar/financeStructureEquity.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/financeStructureEquity.mdx")
  ),
  "@/docs/sidebar/financeStructureEquityInvestor.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/financeStructureEquityInvestor.mdx")
  ),
  "@/docs/sidebar/financeStructureEquityInvestorPreferredDividends.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/financeStructureEquityInvestorPreferredDividends.mdx")
  ),
  "@/docs/sidebar/financeStructureEquityInvestorPreferredRate.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/financeStructureEquityInvestorPreferredRate.mdx")
  ),
  "@/docs/sidebar/financeStructureEquityInvestorRepayments.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/financeStructureEquityInvestorRepayments.mdx")
  ),
  "@/docs/sidebar/financeStructureEquityInvestorStepUp.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/financeStructureEquityInvestorStepUp.mdx")
  ),
  "@/docs/sidebar/financeStructureHasEquityInvestor.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/financeStructureHasEquityInvestor.mdx")
  ),
  "@/docs/sidebar/financeStructureSB7a.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/financeStructureSB7a.mdx")
  ),
  "@/docs/sidebar/financeStructureSellersNote.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/financeStructureSellersNote.mdx")
  ),
  "@/docs/sidebar/lineOfCreditDayOneDrawdown.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/lineOfCreditDayOneDrawdown.mdx")
  ),
  "@/docs/sidebar/lineOfCreditTotalAmount.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/lineOfCreditTotalAmount.mdx")
  ),
  "@/docs/sidebar/opAssAmortOfGoodwill.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/opAssAmortOfGoodwill.mdx")
  ),
  "@/docs/sidebar/opAssCompanyTaxRate.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/opAssCompanyTaxRate.mdx")
  ),
  "@/docs/sidebar/opAssMaintenanceCapEx.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/opAssMaintenanceCapEx.mdx")
  ),
  "@/docs/sidebar/purchaseInfoCashFlow.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/purchaseInfoCashFlow.mdx")
  ),
  "@/docs/sidebar/purchaseInfoPurchaseMultiple.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/purchaseInfoPurchaseMultiple.mdx")
  ),
  "@/docs/sidebar/purchaseInfoPurchasePrice.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/purchaseInfoPurchasePrice.mdx")
  ),
  "@/docs/sidebar/realestateBuisnessValue.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/realestateBuisnessValue.mdx")
  ),
  "@/docs/sidebar/realEstateIncluded.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/realEstateIncluded.mdx")
  ),
  "@/docs/sidebar/sba7aLoanAmortSchedule.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sba7aLoanAmortSchedule.mdx")
  ),
  "@/docs/sidebar/sba7aLoanInterestRate.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sba7aLoanInterestRate.mdx")
  ),
  "@/docs/sidebar/sba7aLoanLoanStartDate.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sba7aLoanLoanStartDate.mdx")
  ),
  "@/docs/sidebar/sba7aLoanRollinDealExpenses.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sba7aLoanRollinDealExpenses.mdx")
  ),
  "@/docs/sidebar/sba7aLoanRollInLoanTransactionFee.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sba7aLoanRollInLoanTransactionFee.mdx")
  ),
  "@/docs/sidebar/sba7aLoanRollinSBAGuarantyFee.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sba7aLoanRollinSBAGuarantyFee.mdx")
  ),
  "@/docs/sidebar/sellersNoteAmortSchedule.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sellersNoteAmortSchedule.mdx")
  ),
  "@/docs/sidebar/sellersNoteHasStandbyProvision.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sellersNoteHasStandbyProvision.mdx")
  ),
  "@/docs/sidebar/sellersNoteInterestRate.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sellersNoteInterestRate.mdx")
  ),
  "@/docs/sidebar/sellersNoteLoanStartDate.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sellersNoteLoanStartDate.mdx")
  ),
  "@/docs/sidebar/sellersNoteStandbyAccrueInterestInFullStandby.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sellersNoteStandbyAccrueInterestInFullStandby.mdx")
  ),
  "@/docs/sidebar/sellersNoteStandbyIsFullStandby.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sellersNoteStandbyIsFullStandby.mdx")
  ),
  "@/docs/sidebar/sellersNoteStandbyPeriod.mdx": lazyReactFallback(
    () => import("@/docs/sidebar/sellersNoteStandbyPeriod.mdx")
  ),
  "@/docs/terms-of-service.mdx": lazyReactFallback(
    () => import("@/docs/terms-of-service.mdx")
  ),
  "@/docs/tooltips/base-year.mdx": lazyReactFallback(
    () => import("@/docs/tooltips/base-year.mdx")
  ),
  "@/docs/tooltips/cash-flow.mdx": lazyReactFallback(
    () => import("@/docs/tooltips/cash-flow.mdx")
  ),
  "@/docs/tooltips/finance-structrue.mdx": lazyReactFallback(
    () => import("@/docs/tooltips/finance-structrue.mdx")
  ),
  "@/docs/tooltips/loan-assumption-sba-7-amortization.mdx": lazyReactFallback(
    () => import("@/docs/tooltips/loan-assumption-sba-7-amortization.mdx")
  ),
  "@/docs/tooltips/loan-assumption-sba-7-interest-rate.mdx": lazyReactFallback(
    () => import("@/docs/tooltips/loan-assumption-sba-7-interest-rate.mdx")
  ),
  "@/docs/tooltips/loan-assumption-sellers-note-amortization.mdx": lazyReactFallback(
    () => import("@/docs/tooltips/loan-assumption-sellers-note-amortization.mdx")
  ),
  "@/docs/tooltips/loan-assumption-sellers-note-interest-rate.mdx": lazyReactFallback(
    () => import("@/docs/tooltips/loan-assumption-sellers-note-interest-rate.mdx")
  ),
  "@/docs/tooltips/target-purchase-multiple.mdx": lazyReactFallback(
    () => import("@/docs/tooltips/target-purchase-multiple.mdx")
  )
} as const;