import { dealDocumentAtom } from "@/atoms/deal";
import { DatePickerInput } from "@mantine/dates";
import { Timestamp } from "firebase/firestore";
import { useAtom } from "jotai";

export const CreatedAtDate = () => {
  const [[deal], setDeal] = useAtom(dealDocumentAtom);

  return (
    <DatePickerInput
      size="lg"
      placeholder="Pick date"
      value={deal?.createdAt.toDate()}
      onChange={(date) => {
        if (!date) return;
        setDeal(
          { ...deal, createdAt: Timestamp.fromDate(date) },
          { merge: true },
        );
      }}
    />
  );
};
