import { manifest } from "@/docs/manifest";

export const Doc = ({ path }: { path: keyof typeof manifest }) => {
  const Doc = manifest[path];

  if (!Doc) {
    return <div>Doc not found.</div>;
  }

  return <Doc />;
};
