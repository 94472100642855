import { Doc } from "@/components/Doc/Doc";
import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import {
  addbacksAndAdjustmentsItemization,
  amortOfDealExpensesMolecule,
  annualDeprAmortizationExpensesMolecule,
  annualMaintenanceCapExMolecule,
  buyersAdjustedEBITDAMarginsMolecule,
  buyersAdjustedEBITDAMolecule,
  buyersCompensationMolecule,
  buyersPurchaseMultipleMolecule,
  companysTaxRateMolecule,
  costOfGoodsSoldItemization,
  distributableFreeCashFlowMolecule,
  grossMarginsMolecule,
  grossProfitMolecule,
  grossRevenueItemization,
  incomeTaxesMolecule,
  lineOfCreditInterestMolecule,
  lineOfCreditPricipalMolecule,
  netIncomeMolecule,
  newOperatingExpensesItemization,
  operatingExpensesItemization,
  otherIncomeMolecule,
  sba7aLoanExpenseInterestMolecule,
  sba7aLoanExpensePricipalMolecule,
  sba7aLoanExpenseTotalMolecule,
  sellersDeprAmortMolecule,
  sellersDiscretionaryEarningsMarginsMolecule,
  sellersDiscretionaryEarningsMolecule,
  sellersEBITDAMolecule,
  sellersIncomeTaxMolecule,
  sellersInterestExpenseMolecule,
  sellersNoteExpenseInterestMolecule,
  sellersNoteExpensePricipalMolecule,
  sellersNoteExpenseTotalMolecule,
  taxableIncomeMolecule,
  totalDebtServiceMolecule,
  totalEquityInvestorDistributionsMolecule,
  totalEquityPreferredDividendsMolecule,
  totalEquityPrincipalRepaymentsMolecule,
  totalEquityProfitDistributionsMolecule,
  unleveredFreeCashFlowMolecule,
  useableFreeCashFlowMolecule,
} from "@/financeModels/owasco/lookback";
import { Divider } from "@mantine/core";
import { CellRow } from "../CellGrid/CellRow";
import { Grid } from "../CellGrid/Grid";
import { Itemization } from "../CellGrid/Itemization";
import { TitleRow } from "../CellGrid/TitleRow";
import { Waterfall } from "../CellGrid/Waterfall";
import { EpochRow } from "./EpochRow";

export const PanelHistoricals = () => {
  let tabYIndex = 0;

  return (
    <Grid w={"100%"} h={"100%"} pos={"relative"}>
      <EpochRow />
      <TitleRow>Company Financials</TitleRow>

      <Waterfall>
        <Itemization
          itemizationAtom={grossRevenueItemization}
          label={
            <LabelExplainer
              showIcon={false}
              label={"Gross Revenue"}
              explanation={
                <Doc path="@/docs/line-items/historicals/gross-revenue.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />
        <Itemization
          itemizationAtom={costOfGoodsSoldItemization}
          label={
            <LabelExplainer
              showIcon={false}
              label={"Cost of Goods Sold (COGS)"}
              explanation={
                <Doc path="@/docs/line-items/historicals/cost-of-goods-sold.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={grossProfitMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc path={"@/docs/line-items/historicals/gross-profit.mdx"} />
              }
            >
              Gross Profit
            </Waterfall.Formula>
          }
        />
        <CellRow
          variant="transparent"
          labelWidth={140}
          tabY={tabYIndex++}
          molecule={grossMarginsMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={"Gross Margins"}
              explanation={
                <Doc path="@/docs/line-items/historicals/gross-margin.mdx" />
              }
            />
          }
        />
        <Itemization
          itemizationAtom={operatingExpensesItemization}
          label={
            <LabelExplainer
              showIcon={false}
              label={"Operating Expenses"}
              explanation={
                <Doc path="@/docs/line-items/historicals/operating-expenses.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />
        <CellRow
          molecule={otherIncomeMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={"Other Income"}
              explanation={
                <Doc path="@/docs/line-items/historicals/other-income.mdx" />
              }
            />
          }
          tabY={tabYIndex++}
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={netIncomeMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc path={"@/docs/line-items/historicals/net-income.mdx"} />
              }
            >
              Net Income
            </Waterfall.Formula>
          }
        />
      </Waterfall>

      <TitleRow children="Seller's Financial Adjustments" />
      <Waterfall>
        <CellRow
          tabY={tabYIndex++}
          molecule={sellersInterestExpenseMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Seller's Interest Expenses`}
              explanation={
                <Doc path="@/docs/line-items/historicals/sellers-interest-expenses.mdx" />
              }
            />
          }
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={sellersDeprAmortMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Seller's Depreciation & Amortization`}
              explanation={
                <Doc path="@/docs/line-items/historicals/sellers-depreciation-amortization.mdx" />
              }
            />
          }
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={sellersIncomeTaxMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Seller's Income Taxes`}
              explanation={
                <Doc path="@/docs/line-items/historicals/sellers-income-taxes.mdx" />
              }
            />
          }
        />

        <CellRow
          tabY={tabYIndex++}
          molecule={sellersEBITDAMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc
                  path={"@/docs/line-items/historicals/sellers-ebitda.mdx"}
                />
              }
            >
              Seller's EBITDA
            </Waterfall.Formula>
          }
        />

        <Itemization
          itemizationAtom={addbacksAndAdjustmentsItemization}
          tabY={tabYIndex++}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Seller's Addbacks`}
              explanation={
                <Doc path="@/docs/line-items/historicals/sellers-addbacks.mdx" />
              }
            />
          }
        />

        <CellRow
          tabY={tabYIndex++}
          molecule={sellersDiscretionaryEarningsMolecule}
          bold
          label={
            <Waterfall.Formula
              explanation={
                <Doc
                  path={"@/docs/line-items/historicals/sellers-adj-ebitda.mdx"}
                />
              }
            >
              Seller's Adjusted EBITDA (SDE)
            </Waterfall.Formula>
          }
        />
      </Waterfall>
      <CellRow
        variant="transparent"
        labelWidth={260}
        tabY={tabYIndex++}
        molecule={sellersDiscretionaryEarningsMarginsMolecule}
        label={
          <LabelExplainer
            showIcon={false}
            label={`Seller's Adjusted EBITDA Margins`}
            explanation={
              <Doc path="@/docs/line-items/historicals/sellers-adj-ebitda-margins.mdx" />
            }
          />
        }
      />

      <TitleRow children="Buyer's Pro-Forma Adjustments" />
      <Waterfall>
        <CellRow
          tabY={tabYIndex++}
          molecule={buyersCompensationMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Buyer's Compensation`}
              explanation={
                <Doc path="@/docs/line-items/historicals/buyers-compensation.mdx" />
              }
            />
          }
        />
        <Itemization
          itemizationAtom={newOperatingExpensesItemization}
          tabY={tabYIndex++}
          label={
            <LabelExplainer
              showIcon={false}
              label={`New Operating Expenses`}
              explanation={
                <Doc path="@/docs/line-items/historicals/new-operating-expenses.mdx" />
              }
            />
          }
        />
        <CellRow
          tabY={tabYIndex++}
          molecule={buyersAdjustedEBITDAMolecule}
          bold
          label={
            <LabelExplainer
              showIcon={false}
              label={`Buyer's Adjusted EBITDA`}
              explanation={
                <Doc path="@/docs/line-items/historicals/buyers-adj-ebitda.mdx" />
              }
            />
          }
        />
      </Waterfall>
      <CellRow
        labelWidth={255}
        variant="transparent"
        tabY={tabYIndex++}
        molecule={buyersAdjustedEBITDAMarginsMolecule}
        label={
          <LabelExplainer
            showIcon={false}
            label={`Buyer's Adjusted EBITDA Margins`}
            explanation={
              <Doc path="@/docs/line-items/historicals/buyers-adj-ebitda-margins.mdx" />
            }
          />
        }
      />

      <CellRow
        labelWidth={210}
        variant="transparent"
        tabY={tabYIndex++}
        molecule={buyersPurchaseMultipleMolecule}
        label={
          <LabelExplainer
            showIcon={false}
            label={`Buyer's Purchase Multiple`}
            explanation={
              <Doc path="@/docs/line-items/historicals/buyers-purchase-multiple.mdx" />
            }
          />
        }
      />

      <TitleRow children="Cash Flow Metrics & Distributions" />

      <Waterfall.Collapse
        label="Unlevered Free Cash Flow"
        explanation={
          <Doc
            path={"@/docs/line-items/historicals/unlevered-free-cash-flow.mdx"}
          />
        }
        tabY={tabYIndex++}
        molecule={unleveredFreeCashFlowMolecule}
      >
        <CellRow
          tabY={tabYIndex++}
          molecule={buyersAdjustedEBITDAMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Buyer's Adjusted EBITDA`}
              explanation={
                <Doc path="@/docs/line-items/historicals/buyers-adj-ebitda.mdx" />
              }
            />
          }
        />
        <Waterfall.Collapse
          explanation={
            <Doc path={"@/docs/line-items/historicals/income-taxes.mdx"} />
          }
          label="Income Taxes"
          tabY={tabYIndex++}
          molecule={incomeTaxesMolecule}
        >
          <CellRow
            tabY={tabYIndex++}
            molecule={annualMaintenanceCapExMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Annual Maintenance CapEx`}
                explanation={
                  <Doc path="@/docs/line-items/historicals/annual-maintenance-capex.mdx" />
                }
              />
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={sba7aLoanExpenseInterestMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Interest - SBA 7(a) Loan`}
                explanation={
                  <Doc path="@/docs/line-items/historicals/sba-7a-loan-interest.mdx" />
                }
              />
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={sellersNoteExpenseInterestMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Interest - Seller's Note`}
                explanation={
                  <Doc path="@/docs/line-items/historicals/sellers-interest-expenses.mdx" />
                }
              />
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={annualDeprAmortizationExpensesMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Depreciation & Amortization`}
                explanation={
                  <Doc path="@/docs/line-items/historicals/depreciation-and-amortization.mdx" />
                }
              />
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={amortOfDealExpensesMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Amortization of Deal Expenses`}
                explanation={
                  <Doc path="@/docs/line-items/historicals/amortization-of-deal-expenses.mdx" />
                }
              />
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={taxableIncomeMolecule}
            bold
            label={
              <Waterfall.Formula
                explanation={
                  <Doc
                    path={"@/docs/line-items/historicals/taxable-income.mdx"}
                  />
                }
              >
                Taxable Income
              </Waterfall.Formula>
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={companysTaxRateMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Company's Tax Rate`}
                explanation={
                  <Doc path="@/docs/line-items/historicals/companys-tax-rate.mdx" />
                }
              />
            }
          />
        </Waterfall.Collapse>
        <CellRow
          tabY={tabYIndex++}
          molecule={annualMaintenanceCapExMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Annual Maintenance CapEx`}
              explanation={
                <Doc path="@/docs/line-items/historicals/annual-maintenance-capex.mdx" />
              }
            />
          }
        />
      </Waterfall.Collapse>

      <Divider mb="sm" />

      <Waterfall.Collapse
        tabY={tabYIndex++}
        molecule={distributableFreeCashFlowMolecule}
        explanation={
          <Doc
            path={
              "@/docs/line-items/historicals/distributable-free-cash-flow.mdx"
            }
          />
        }
        label="Distributable Free Cash Flow"
      >
        <CellRow
          tabY={tabYIndex++}
          molecule={unleveredFreeCashFlowMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Unlevered Free Cash Flow`}
              explanation={
                <Doc path="@/docs/line-items/historicals/unlevered-free-cash-flow.mdx" />
              }
            />
          }
        />
        <Waterfall.Collapse
          tabY={tabYIndex++}
          molecule={totalDebtServiceMolecule}
          explanation={
            <Doc
              path={"@/docs/line-items/historicals/total-debt-service.mdx"}
            />
          }
          label="Total Debt Service"
        >
          <Waterfall.Collapse
            explanation={
              <Doc
                path={"@/docs/line-items/historicals/sba7a-loan-total.mdx"}
              />
            }
            label="SBA 7(a) Loan Total"
            tabY={tabYIndex++}
            molecule={sba7aLoanExpenseTotalMolecule}
          >
            <CellRow
              tabY={tabYIndex++}
              molecule={sba7aLoanExpensePricipalMolecule}
              label={
                <LabelExplainer
                  showIcon={false}
                  label={`Principal - SBA 7(a) Loan`}
                  explanation={
                    <Doc path="@/docs/line-items/historicals/sba-7a-loan-principal.mdx" />
                  }
                />
              }
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={sba7aLoanExpenseInterestMolecule}
              label={
                <LabelExplainer
                  showIcon={false}
                  label={`Interest - SBA 7(a) Loan`}
                  explanation={
                    <Doc path="@/docs/line-items/historicals/sba-7a-loan-interest.mdx" />
                  }
                />
              }
            />
          </Waterfall.Collapse>

          <Waterfall.Collapse
            explanation={
              <Doc
                path={"@/docs/line-items/historicals/line-of-credit-total.mdx"}
              />
            }
            label={"Line Of Credit (Working Capital)"}
            tabY={tabYIndex++}
            molecule={lineOfCreditInterestMolecule}
          >
            <CellRow
              tabY={tabYIndex++}
              molecule={lineOfCreditPricipalMolecule}
              label={
                <LabelExplainer
                  showIcon={false}
                  label={`Principal - Line Of Credit`}
                  explanation={
                    <Doc path="@/docs/line-items/historicals/line-of-credit-principal.mdx" />
                  }
                />
              }
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={lineOfCreditInterestMolecule}
              label={
                <LabelExplainer
                  showIcon={false}
                  label={`Interest - Line Of Credit`}
                  explanation={
                    <Doc path="@/docs/line-items/historicals/line-of-credit-interest.mdx" />
                  }
                />
              }
            />
          </Waterfall.Collapse>

          <Waterfall.Collapse
            explanation={
              <Doc
                path={"@/docs/line-items/historicals/sellers-note-total.mdx"}
              />
            }
            label={"Seller's Note Total"}
            tabY={tabYIndex++}
            molecule={sellersNoteExpenseTotalMolecule}
          >
            <CellRow
              tabY={tabYIndex++}
              molecule={sellersNoteExpensePricipalMolecule}
              label={
                <LabelExplainer
                  showIcon={false}
                  label={`Principal - Seller's Note`}
                  explanation={
                    <Doc path="@/docs/line-items/historicals/sellers-note-principal.mdx" />
                  }
                />
              }
            />
            <CellRow
              tabY={tabYIndex++}
              molecule={sellersNoteExpenseInterestMolecule}
              label={
                <LabelExplainer
                  showIcon={false}
                  label={`Interest - Seller's Note`}
                  explanation={
                    <Doc path="@/docs/line-items/historicals/sellers-note-interest.mdx" />
                  }
                />
              }
            />
          </Waterfall.Collapse>
        </Waterfall.Collapse>
      </Waterfall.Collapse>
      <Divider mb="sm" />
      <Waterfall.Collapse
        explanation={
          <Doc
            path={"@/docs/line-items/historicals/usable-free-cash-flow.mdx"}
          />
        }
        label={"Usable Free Cash Flow"}
        tabY={tabYIndex++}
        molecule={useableFreeCashFlowMolecule}
      >
        <CellRow
          tabY={tabYIndex++}
          molecule={distributableFreeCashFlowMolecule}
          label={
            <LabelExplainer
              showIcon={false}
              label={`Distributable Free Cash Flow`}
              explanation={
                <Doc path="@/docs/line-items/historicals/distributable-free-cash-flow.mdx" />
              }
            />
          }
        />
        <Waterfall.Collapse
          explanation={
            <Doc
              path={
                "@/docs/line-items/historicals/equity-investor-distributions.mdx"
              }
            />
          }
          label="Equity Investor Distributions"
          tabY={tabYIndex++}
          molecule={totalEquityInvestorDistributionsMolecule}
        >
          <CellRow
            tabY={tabYIndex++}
            molecule={totalEquityPreferredDividendsMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Preferred Dividends`}
                explanation={
                  <Doc path="@/docs/line-items/historicals/equity-investor-prefered-dividends.mdx" />
                }
              />
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={totalEquityPrincipalRepaymentsMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Principal Repayments`}
                explanation={
                  <Doc path="@/docs/line-items/historicals/equity-investor-principal-repayments.mdx" />
                }
              />
            }
          />
          <CellRow
            tabY={tabYIndex++}
            molecule={totalEquityProfitDistributionsMolecule}
            label={
              <LabelExplainer
                showIcon={false}
                label={`Profit Distributions`}
                explanation={
                  <Doc path="@/docs/line-items/historicals/equity-investor-profit-distributions.mdx" />
                }
              />
            }
          />
        </Waterfall.Collapse>
      </Waterfall.Collapse>

      <Divider mb={"calc(25vh + 100px)"} />
    </Grid>
  );
};
