import {
  businessAcquisitionCostAtom,
  equityDollarAtom,
  equityInvestorDollarAtom,
  isEquityInvestorsIncludedAtom,
  isRealEstateIncludedAtom,
  isRollinDealExpensesAtom,
  isRollinLoanTransactionFeeAtom,
  isRollinSBAGuarantyFeeAtom,
  lenderLineOfCreditTotalAmountAtom,
  loanTransactionFeeAtom,
  realEstateDollarValueAtom,
  sbaGuarantyFeeAtom,
  sbaLoanTotalWithFeesWithoutLOCAtom,
  sellersNoteDollarAtom,
  targetPurchasePriceAtom,
  totalDealExpensesAtom,
} from "@/financeModels/owasco/general";
import { MathResult } from "@/financeModels/types";
import { atom } from "jotai";

export const usesAtom = atom((get) => {
  const targetPurchasePrice = get(targetPurchasePriceAtom);
  const dealExpenses = get(totalDealExpensesAtom);
  const loanTransactionFee = get(loanTransactionFeeAtom);
  const sbaguarantyFee = get(sbaGuarantyFeeAtom);
  const isRealEstateIncluded = get(isRealEstateIncludedAtom);
  const realEstateDollarValue = get(realEstateDollarValueAtom);
  const lineOfCredit = get(lenderLineOfCreditTotalAmountAtom);
  const isRollinDealExpenses = get(isRollinDealExpensesAtom);
  const businessAcquisitionCost = get(businessAcquisitionCostAtom);

  type Item = [string, MathResult];

  const items = [
    ...(isRealEstateIncluded
      ? [
          ["Business Acquisition", businessAcquisitionCost],
          ["Real Estate Transaction", realEstateDollarValue],
        ]
      : [["Business Acquisition", targetPurchasePrice]]),

    ...(isRollinDealExpenses ? [["Deal Expenses", dealExpenses] as Item] : []),

    ["Loan Transaction Fee", loanTransactionFee],
    ["SBA Guaranty Fee", sbaguarantyFee],
  ];

  if (lineOfCredit)
    items.push(["Line of Credit (Working Capital)", lineOfCredit]);

  return items;
});

export const usesTotalCostAtom = atom((get) => {
  const items = get(usesAtom);
  return items.reduce((acc, [, value]) => acc + Number(value), 0);
});

export const sourcesAtom = atom((get) => {
  const sbaLoanDebt = get(sbaLoanTotalWithFeesWithoutLOCAtom);
  const sellersNote = get(sellersNoteDollarAtom);
  const isEquityInvestorsIncluded = get(isEquityInvestorsIncludedAtom);
  const equityDollar = get(equityDollarAtom);
  const equityInvestorDollar = get(equityInvestorDollarAtom);
  const isRollinLoanTransactionFee = get(isRollinLoanTransactionFeeAtom);
  const isRollinSBAGuarantyFee = get(isRollinSBAGuarantyFeeAtom);
  const loanTransactionFee = get(loanTransactionFeeAtom);
  const sbaguarantyFee = get(sbaGuarantyFeeAtom);
  const lineOfCredit = get(lenderLineOfCreditTotalAmountAtom);

  const items: [string, MathResult][] = [
    ["SBA 7(a) Loan", sbaLoanDebt],
    ...(lineOfCredit
      ? [["Line of Credit (Working Capital)", lineOfCredit]]
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([] as any)),
    ["Seller's Note", sellersNote],
  ];

  if (!isEquityInvestorsIncluded) {
    items.push(["Equity", equityDollar]);
  } else {
    items.push(["Equity - Searcher", equityDollar]);
    items.push(["Equity - Investor", equityInvestorDollar]);
  }

  if (!isRollinLoanTransactionFee)
    items.push(["Loan Transaction Fee", loanTransactionFee]);

  if (!isRollinSBAGuarantyFee) items.push(["SBA Guaranty Fee", sbaguarantyFee]);

  return items;
});

export const sourcesTotalCostAtom = atom((get) => {
  const items = get(sourcesAtom);
  return items.reduce((acc, [, value]) => acc + Number(value), 0);
});
