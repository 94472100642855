import { Box, BoxComponentProps } from "@mantine/core";
import { ReactNode } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";

export const LayoutPage = ({
  children,
  ...props
}: { children: ReactNode } & BoxComponentProps) => {
  return (
    <Box {...props}>
      <Header />
      <Box mih={"calc(100dvh - 140px)"}>{children}</Box>

      <Footer />
    </Box>
  );
};
