import {
  cashOnReturnAtom,
  cashOnReturnTotalAtom,
} from "@/financeModels/owasco/equity";
import { monospaceFontFamily } from "@/theme";
import { formatDecimalPercent } from "@/utils/format";
import { Table } from "@mantine/core";
import { useAtomValue } from "jotai";

export const CashOnReturnTable = () => {
  const cashOnReturn = useAtomValue(cashOnReturnAtom);
  const cashOnReturnTotal = useAtomValue(cashOnReturnTotalAtom);

  const items = [...cashOnReturn, cashOnReturnTotal];

  return (
    <Table striped withColumnBorders>
      <Table.Thead
        style={{
          position: "sticky",
          top: "0",
          background: "white",
        }}
      >
        <Table.Tr>
          <Table.Th
            ta={"center"}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Cash-On-Cash
            <br />
            Return
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {items.map((item, i) => (
          <Table.Tr key={i}>
            <Table.Td ta={"right"} ff={monospaceFontFamily}>
              {formatDecimalPercent(item)}
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};
