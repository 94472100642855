import { Box, Flex, Group, ScrollArea, SimpleGrid, Stack } from "@mantine/core";
import { Brokerage } from "./Brokerage";
import { Company } from "./Company";
import { Contacts } from "./Contacts";
import { ListedFinancials } from "./ListedFinancials";
import { Notes } from "./Notes";

export const Main = () => {
  return (
    <Flex w={"100%"}>
      <ScrollArea h={"calc(100dvh - 43px)"} w={{ base: "100%", lg: "64%" }}>
        <Stack p="sm">
          <Company />
          <SimpleGrid cols={2}>
            <Brokerage />
            <ListedFinancials />
          </SimpleGrid>
          <Contacts />
          <Box hiddenFrom="lg">
            <Notes />
          </Box>
        </Stack>
      </ScrollArea>
      <Group flex={1} bg="white" align="top" visibleFrom="lg">
        <Notes />
      </Group>
    </Flex>
  );
};
