import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { Title } from "@/components/PageDealTracker/Sidebar/CategoryList";
import { Route } from "@/paths";
import { Stack, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { BottomActions } from "./BottomActions";
import { CategorySelector } from "./CategorySelector";
import { ModelsList } from "./ModelsList";

export const Sidebar = () => {
  return (
    <LayoutWorkstation.Sidebar size={"sm"}>
      <LayoutWorkstation.Header>
        <LayoutWorkstation.Header.Breadcrumbs>
          <Text component={Link} mt={".13em"} fw={600} to={Route.DealTracker}>
            Deal Tracker
          </Text>
          <LayoutWorkstation.Header.BreadcrumbSeperator />
          <LayoutWorkstation.Header.BreadcrumbTitle>
            Deal
          </LayoutWorkstation.Header.BreadcrumbTitle>
        </LayoutWorkstation.Header.Breadcrumbs>
      </LayoutWorkstation.Header>
      <Stack flex={1} p="xs" justify="space-between">
        <Stack gap={"xs"}>
          <ModelsList />

          <Title />
          <CategorySelector />
        </Stack>
        <BottomActions />
      </Stack>
    </LayoutWorkstation.Sidebar>
  );
};
