import { DeferredRender } from "@/components/DeferedRender/DeferedRender";
import { Box, Group, Tooltip } from "@mantine/core";
import { ReactNode } from "react";
import { RiQuestionLine } from "react-icons/ri";

export const ComputedLabel = ({
  tooltip,
  children,
  underline = false,
  ff,
}: {
  tooltip: ReactNode;
  children: ReactNode;
  underline?: boolean;
  ff?: string;
}) => (
  <DeferredRender>
    <Tooltip position="right" label={<Group gap={4}>{tooltip}</Group>}>
      <Box
        style={{
          alignSelf: "flex-start",
        }}
      >
        {/* <LabelExplainer explanation="Test 123"> */}
        <Group
          gap={4}
          align="center"
          lh={1.2}
          justify="left"
          style={{
            cursor: "help",
          }}
          td={underline ? "underline" : "none"}
          ff={ff}
        >
          {children}
          <RiQuestionLine size={13} />
        </Group>
        {/* </LabelExplainer> */}
      </Box>
    </Tooltip>
  </DeferredRender>
);
