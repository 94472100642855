import TooltipAmort from "@/docs/tooltips/loan-assumption-sellers-note-amortization.mdx";
import { toNumberOrNull } from "@/utils/math";
import { Group, Select } from "@mantine/core";
import { LiaFileContractSolid } from "react-icons/lia";

import {
  sellersNoteAmortizationScheduleAtom,
  sellersNoteInterestRateAtom,
} from "@/financeModels/liteAtoms";
import { useAtom } from "jotai";
import { LabelExplainer } from "../LabelExplainer/LabelExplainer";
import { QuestionnaireSection } from "./QuestionnaireSection";

export const SegmentLoanAssumptionSellersNote = () => {
  const [sellersNoteInterestRate, setSellersNoteInterestRate] = useAtom(
    sellersNoteInterestRateAtom,
  );
  const [sellersNoteAmortizationSchedule, setSellersNoteAmortizationSchedule] =
    useAtom(sellersNoteAmortizationScheduleAtom);

  const percents = [...Array(12).keys()].map((x) => ({
    value: `${(0.01 * x + 0.01).toFixed(2)}`,
    label: `${x + 1}.00%`,
  }));

  const years = [...Array(10).keys()].map((x) => ({
    value: `${x + 1}`,
    label: `${x + 1} years`,
  }));

  return (
    <QuestionnaireSection
      label={"Loan Assumptions: Seller's Note"}
      icon={LiaFileContractSolid}
    >
      <Group justify="space-evenly" align="center" gap={60} ml={-16}>
        <Select
          withCheckIcon={false}
          allowDeselect={false}
          w={160}
          size="lg"
          data={percents}
          label={
            <LabelExplainer
              label="Interest Rate"
              explanation={<TooltipAmort />}
              preventDefault
            />
          }
          value={(toNumberOrNull(sellersNoteInterestRate) ?? 0).toString()}
          onChange={(value) => {
            setSellersNoteInterestRate(toNumberOrNull(Number(value)));
          }}
        />
        <Select
          withCheckIcon={false}
          allowDeselect={false}
          w={160}
          size="lg"
          data={years}
          label={
            <LabelExplainer
              label="Amortization"
              explanation={<TooltipAmort />}
              preventDefault
            />
          }
          value={(
            toNumberOrNull(sellersNoteAmortizationSchedule) ?? 0
          ).toString()}
          onChange={(value) => {
            setSellersNoteAmortizationSchedule(toNumberOrNull(Number(value)));
          }}
        />
      </Group>
    </QuestionnaireSection>
  );
};
