import { getCategoryIndex } from "@/atoms/dealTracker/category";
import { formatDollar } from "@/utils/format";
import { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import { Timestamp } from "firebase/firestore";
import parseMoney from "parse-money";
import { ActionRenderer } from "./Grid/ActionsRenderer";
import { CategoryEditor } from "./Grid/CategoryEditor";
import { CategoryRender } from "./Grid/CategoryRender";
import { ListingLinkRenderer } from "./Grid/ListingLinkRenderer";
import { ModelRenderer } from "./Grid/ModelRenderer";
import { NameRenderer } from "./Grid/NameRenderer";

export const defaultColDef: ColDef = {
  flex: 1,
  //filter: true,
  editable: true,
  sortable: true,
  minWidth: 140,
  sortingOrder: ["asc", "desc"],
  valueParser: (params) => params.newValue,
};

const checkBoxOptions = {
  resizable: false,
  cellDataType: "boolean",
  filter: false,
  minWidth: 40,
  maxWidth: 40,
  cellStyle: { paddingLeft: 10 },
  headerClass: "ag-header-cell checkbox-cell-header",
};

const dollarOptions: Partial<ColDef> = {
  // parse value to float, fix columns and decimals
  valueParser: (params) => parseMoney(params.newValue.toString())?.amount || 0,
  valueFormatter: (params: ValueFormatterParams) =>
    params.value > 0 ? formatDollar(params.value).replace(".00", "") : "",
};

// Column Definitions: Defines & controls grid columns.
export const colDefs: ColDef[] = [
  { field: "companyName", minWidth: 255, cellRenderer: NameRenderer },
  {
    field: "createdAt",
    headerName: "Created",
    cellDataType: "date",
    valueSetter: (params) => {
      // date to firestore timestamp

      console.log(params.newValue);

      params.data.createdAt = Timestamp.fromDate(new Date(params.newValue));
      return true;
    },
    valueGetter: (params) => params?.data?.createdAt?.toDate(),
    //editable: false,
    minWidth: 118,
    maxWidth: 118,
    //sort: "desc",
    //sortIndex: 1,
    //cellStyle: { textAlign: "center" },
  },
  {
    field: "listingLink",
    cellRenderer: ListingLinkRenderer,
  },
  {
    field: "categoryId",
    headerName: "Status",

    cellRenderer: CategoryRender,
    cellEditor: CategoryEditor,
    cellEditorPopup: true,
    sort: "asc",
    //sortIndex: 0,
    filter: true,
    suppressHeaderFilterButton: true,
    minWidth: 148,

    comparator: (valueA, valueB, _nodeA, _nodeB, isDescending) => {
      const indexA = getCategoryIndex(valueA);
      const indexB = getCategoryIndex(valueB);

      if (indexA !== indexB) {
        return isDescending ? -1 * (indexB - indexA) : indexA - indexB;
      }

      // sort by createdDate
      const dateA = _nodeA.data.createdAt?.toDate()?.getTime() || 0;
      const dateB = _nodeB.data.createdAt?.toDate()?.getTime() || 0;
      return !isDescending ? dateB - dateA : dateA - dateB;
    },
  },

  {
    field: "ndaCompleted",
    headerName: "NDA",
    headerTooltip: "NDA Received",
    ...checkBoxOptions,
  },
  {
    field: "cimCompleted",
    headerName: "CIM",
    headerTooltip: "CIM Received",
    ...checkBoxOptions,
  },
  {
    field: "includesRealEstate",
    headerName: "🏢",
    hide: true,
    headerTooltip: "Real Estate included",
    ...checkBoxOptions,
  },
  {
    field: "modelIds",
    headerName: "Model",
    editable: false,
    filter: false,
    sortable: false,
    cellRenderer: ModelRenderer,
    minWidth: 200,
    maxWidth: 200,
  },
  { field: "cashFlow", headerName: "Cash Flow (SDE)", ...dollarOptions },
  { field: "purchasePrice", ...dollarOptions },
  {
    field: "purchaseMultiple",
    headerName: "Purchase Multiple",
    editable: false,
    valueGetter: (params) => {
      if (!params.data.cashFlow) return null;
      if (!params.data.purchasePrice) return null;

      return params.data.purchasePrice / params.data.cashFlow;
    },
    valueFormatter: (params) =>
      params.value ? params.value.toFixed(2) + "x" : "",
    cellStyle: {
      cursor: "not-allowed",
      background:
        "linear-gradient(45deg, rgb(245, 245, 245) 25%, rgb(249, 249, 249) 25%, rgb(249, 249, 249) 50%, rgb(245, 245, 245) 50%, rgb(245, 245, 245) 75%, rgb(249, 249, 249) 75%, rgb(249, 249, 249) 100%) 0px 0px / 20px 20px",
    },
    headerTooltip: "Auto-calculated using Cash Flow (SDE) and Purchase Price",
  },
  { field: "remarks" },

  {
    field: "revenue",
    hide: true,
    ...dollarOptions,
  },

  { field: "location", hide: true },
  { field: "brokerage", hide: true },
  { field: "broker", hide: true },

  {
    field: "actions",
    editable: false,
    filter: false,
    sortable: false,
    cellRenderer: ActionRenderer,
  },
];
