import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { Stack } from "@mantine/core";
import { CategoryList } from "./CategoryList";
import { CreateDealButton } from "./CreateDealButton";
export const Sidebar = () => {
  return (
    <LayoutWorkstation.Sidebar size={"sm"}>
      <LayoutWorkstation.Header>
        <LayoutWorkstation.Header.Breadcrumbs>
          <LayoutWorkstation.Header.BreadcrumbTitle>
            Deal Tracker
          </LayoutWorkstation.Header.BreadcrumbTitle>
        </LayoutWorkstation.Header.Breadcrumbs>
      </LayoutWorkstation.Header>
      <Stack p="sm">
        <CreateDealButton />
        <CategoryList />
      </Stack>
    </LayoutWorkstation.Sidebar>
  );
};
