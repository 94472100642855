import { cloneFromEpochsAtom } from "@/financeModels/owasco/clone";
import { iterationCountAtom } from "@/financeModels/owasco/iterations";
import {
  dscrMolecule,
  yearsFromSbaLoanStartDateAtom,
} from "@/financeModels/owasco/projections";
import { ZIndexOrder } from "@/theme";
import {
  ActionIcon,
  BoxComponentProps,
  Button,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { useAtomValue, useSetAtom } from "jotai";
import { ReactNode } from "react";
import { IoArrowRedo } from "react-icons/io5";
import { LuCalendarClock } from "react-icons/lu";
import { TbTableImport } from "react-icons/tb";
import { Column } from "../CellGrid/Column";
import { DSCRCell } from "../CellGrid/DSCRCell";
import { defaultGrowthCellWidth } from "../CellGrid/GrowthCell";
import { defaultLabelColumnWidth } from "../CellGrid/LabelColumn";
import { Row } from "../CellGrid/Row";
import { lineSize } from "../CellGrid/Waterfall";
import { defaultHeight } from "../CellGrid/cell/Wrapper";
import { ProjectionYearModal } from "./ProjectionYearModal";

export const IterationRow = ({ ...props }: BoxComponentProps) => {
  const years = useAtomValue(yearsFromSbaLoanStartDateAtom);
  const dscrAtoms = useAtomValue(dscrMolecule);
  const iterationCount = useAtomValue(iterationCountAtom);
  const clone = useSetAtom(cloneFromEpochsAtom);
  const projectionModalDisclosure = useDisclosure();
  const openImportHisoricalsModal = () =>
    modals.openConfirmModal({
      title: "Import Historical Data",
      children: (
        <Text size="sm">
          Importing data from your lookback table will overwrite the base year
          for your projection table. This cannot be undone. Proceed?
        </Text>
      ),
      labels: { confirm: "Overwrite Projection Base Year", cancel: "Cancel" },
      onConfirm: clone,
    });

  return (
    <>
      <Row
        {...props}
        style={{
          position: "sticky",
          top: 0,
          zIndex: ZIndexOrder.CellGridIterationRow,
          borderBottom: "1px solid #ddd",
        }}
        bg={"gray.3"}
      >
        <div
          style={{
            width: defaultLabelColumnWidth + lineSize,
          }}
        >
          <Tooltip position="right" label={"Import Historical Financials"}>
            <ActionIcon
              variant="transparent"
              color="gray.9"
              onClick={openImportHisoricalsModal}
            >
              <TbTableImport height={"100%"} />
            </ActionIcon>
          </Tooltip>
        </div>
        {dscrAtoms.map((atom, i) =>
          i === 1 ? (
            <Column key={`${i}`} justify="center" align={"center"}>
              <Stack
                justify="center"
                align={"center"}
                w={defaultGrowthCellWidth}
                bg={"gray.3"}
                h={defaultHeight}
                style={{
                  fontWeight: 500,

                  //borderTopRightRadius: 8,
                  borderBottom: "1px solid var(--mantine-color-gray-5)",
                }}
              >
                Growth Rate (%)
              </Stack>
            </Column>
          ) : (
            <Column key={`${i}`}>
              <DSCRCell dscrAtom={atom} {...(i === 0 ? { bg: "white" } : {})}>
                <Stack gap={1}>
                  <Text lh={1} h={14} fz={"md"} fw={500}>
                    Year {i < 1 ? i : i - 1}
                  </Text>
                  <Text
                    fz={"xs"}
                    style={{
                      fontSize: 9,
                    }}
                    h={10}
                    lh={1}
                  >
                    {i < 1 ? "Base Year" : (years[i - 2] as ReactNode)}
                  </Text>
                </Stack>
              </DSCRCell>
            </Column>
          ),
        )}
        <Button
          leftSection={
            iterationCount ? (
              <LuCalendarClock size={22} />
            ) : (
              <IoArrowRedo size={22} />
            )
          }
          h={defaultHeight}
          onClick={projectionModalDisclosure[1].open}
          justify="left"
          mx="sm"
          //variant="default"
        >
          {!iterationCount ? "Create Projection Table" : "Change Projection"}
        </Button>
        <ProjectionYearModal disclosure={projectionModalDisclosure} />
      </Row>
    </>
  );
};
