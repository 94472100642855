import { Select, SelectProps } from "@mantine/core";
import { SidebarInputProps } from "./types";

export const InputSelect = ({
  labelWidth = 181,
  ...props
}: SidebarInputProps & SelectProps) => {
  return (
    <Select
      withCheckIcon={false}
      size="sm"
      styles={{
        root: { display: "flex", alignItems: "center" },
        label: { width: labelWidth },
        wrapper: { flex: 1 },
      }}
      {...props}
    />
  );
};
