import { Deal, useRemoveDeal } from "@/hooks/useDeals";
import { Route } from "@/paths";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { ActionIcon, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { MdDelete, MdEditDocument } from "react-icons/md";
import { Link, generatePath } from "react-router-dom";

export const ActionRenderer = (props: CustomCellRendererProps) => {
  const [removeDeal] = useRemoveDeal();
  const deal = props.node.data as Deal;
  const companyName = deal.companyName || "Unlabeled";

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: "Delete your deal",

      children: (
        <Text size="md">
          Are you sure you want to delete the <strong>"{companyName}"</strong>{" "}
          deal? This action is destructive and can't be undone.
        </Text>
      ),
      labels: {
        confirm: `Delete the deal`,
        cancel: "Cancel, keep deal",
      },
      confirmProps: { color: "red" },
      onConfirm: () => removeDeal(deal.id),
    });

  return (
    <div
      style={{
        display: "flex",
        gap: 5,
        height: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <ActionIcon
        variant={"light"}
        component={Link}
        to={generatePath(Route.Deal, {
          dealId: deal.id,
        })}
      >
        <MdEditDocument />
      </ActionIcon>
      <ActionIcon variant={"light"} onClick={openDeleteModal}>
        <MdDelete />
      </ActionIcon>
    </div>
  );
};
