import TooltipFinanceStructrue from "@/docs/tooltips/finance-structrue.mdx";
import {
  equityAmountAtom,
  equityPercentAtom,
  sbaLoanAmountAtom,
  sbaLoanPercentAtom,
  sellersNoteAmountAtom,
  sellersNotePercentAtom,
  targetPurchasePriceAtom,
} from "@/financeModels/liteAtoms";
import { inputFontFamily } from "@/theme";
import { formatDollar } from "@/utils/format";
import { isBetween, toNumberOrNull } from "@/utils/math";
import {
  ComboboxData,
  Group,
  NumberFormatter,
  SegmentedControl,
  Select,
  Table,
  Text,
} from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { RiPieChartLine } from "react-icons/ri";
import { BigDollarInput } from "../BigDollarInput/BigDollarInput";
import { LabelExplainer } from "../LabelExplainer/LabelExplainer";
import { QuestionnaireSection } from "./QuestionnaireSection";
import classes from "./SegmentFinanceStructure.module.css";

export const SegmentFinanceStructure = () => {
  const [isDirty, setIsDirty] = useState(false);

  const [isPercentUnits, setPercentUnits] = useState(true);
  const targetPurchasePrice = useAtomValue(targetPurchasePriceAtom);
  const [sbaLoanPercent, setSbaLoanPercent] = useAtom(sbaLoanPercentAtom);
  const [sbaLoanAmount] = useAtom(sbaLoanAmountAtom);
  const [equityAmount] = useAtom(equityAmountAtom);
  const [equityPercent, setEquityPercent] = useAtom(equityPercentAtom);
  const [sellersNoteAmount] = useAtom(sellersNoteAmountAtom);
  const [sellersNotePercent, setSellersNotePercent] = useAtom(
    sellersNotePercentAtom,
  );

  const amountTotal =
    (toNumberOrNull(sbaLoanAmount) ?? 0) +
    (toNumberOrNull(sellersNoteAmount) ?? 0) +
    (toNumberOrNull(equityAmount) ?? 0);

  const percentTotal =
    (toNumberOrNull(sbaLoanPercent) ?? 0) +
    (toNumberOrNull(sellersNotePercent) ?? 0) +
    (toNumberOrNull(equityPercent) ?? 0);

  const percents = [...Array(90 / 5 + 1).keys()].map((x) => ({
    value: `${Number((x * 0.05).toFixed(2)).toString()}`,
    label: `${(x * 0.05 * 100).toFixed(2).replace(".00", "")}%`,
  }));

  let error: string | undefined = undefined;
  if (1 - percentTotal > 0.001 || percentTotal > 1)
    error = `Percent total must equal 100%. Total amount should equal target purchase price.`;
  if (isBetween(sbaLoanAmount, 5000000, 50000000))
    error = "The maximum allowable amount for an SBA 7(a) loan is $5 million.";

  return (
    <QuestionnaireSection
      icon={RiPieChartLine}
      label={
        <LabelExplainer
          label="Finance Structure"
          explanation={<TooltipFinanceStructrue />}
        />
      }
      error={error}
    >
      <Table withRowBorders={false} className={classes.table}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th w={200}></Table.Th>
            <Table.Th colSpan={2}>
              <SegmentedControl
                color="accent"
                size="md"
                fullWidth
                data={[
                  { label: "Percent (%)", value: "percent" },
                  { label: "Amount ($)", value: "dollar" },
                ]}
                value={isPercentUnits ? "percent" : "dollar"}
                onChange={(value) => {
                  setPercentUnits(value === "percent");
                }}
              />
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Row
            label="SBA 7(a) Loan"
            value={toNumberOrNull(sbaLoanPercent) ?? 0}
            onChange={(value) => {
              const sbaLoanPercent = round(toNumberOrNull(value) ?? 0);
              const cleanSellerNotePercent = round((1 - sbaLoanPercent) / 2);
              const cleanEquityPercent = round(
                1 - sbaLoanPercent - cleanSellerNotePercent,
              );

              if (isDirty) return setSbaLoanPercent(sbaLoanPercent);

              setSbaLoanPercent(sbaLoanPercent);
              setSellersNotePercent(cleanSellerNotePercent);
              setEquityPercent(cleanEquityPercent);
            }}
            targetPurchasePrice={Number(targetPurchasePrice)}
            data={[...percents].reverse()}
            amount={toNumberOrNull(sbaLoanAmount) ?? 0}
            isPercentUnits={isPercentUnits}
          />
          <Row
            label="Seller's Note"
            value={toNumberOrNull(sellersNotePercent) ?? 0}
            onChange={(value) => {
              setSellersNotePercent(toNumberOrNull(value));
              setIsDirty(true);
            }}
            targetPurchasePrice={Number(targetPurchasePrice)}
            data={percents}
            amount={toNumberOrNull(sellersNoteAmount) ?? 0}
            isPercentUnits={isPercentUnits}
          />
          <Row
            label="Equity"
            value={toNumberOrNull(equityPercent) ?? 0}
            onChange={(value) => {
              setEquityPercent(toNumberOrNull(value));
              setIsDirty(true);
            }}
            targetPurchasePrice={Number(targetPurchasePrice)}
            data={percents}
            amount={toNumberOrNull(equityAmount) ?? 0}
            mb="md"
            isPercentUnits={isPercentUnits}
          />
          <Table.Tr
            style={{
              borderTop: "1px solid #eee",
            }}
            visibleFrom="sm"
          >
            <Table.Td>
              <Text mt={"xs"} fw={500} fz={"lg"}>
                Total
              </Text>
            </Table.Td>
            <Table.Td colSpan={2}>
              <Group ff={inputFontFamily}>
                <Text mt={"xs"} fz={"lg"} w={"45%"} ta={"center"}>
                  {percentTotal > 0 && (
                    <NumberFormatter
                      thousandSeparator
                      decimalScale={0}
                      suffix="%"
                      value={percentTotal * 100}
                    />
                  )}
                </Text>

                <Text mt={"xs"} fz={"lg"} ta="center" w={"45%"}>
                  {amountTotal > 0 &&
                    formatDollar(amountTotal).replace(".00", "")}
                </Text>
              </Group>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </QuestionnaireSection>
  );
};

const Row = ({
  data,
  amount,
  label,
  value,
  onChange,
  mb,
  isPercentUnits,
  targetPurchasePrice,
}: {
  data: ComboboxData;
  amount: number;
  value: number;
  targetPurchasePrice: number;
  label: string;
  onChange: (value: number) => void;
  mb?: string;
  isPercentUnits?: boolean;
}) => {
  const [dollarValue, setDollarValue] = useState(amount);

  useEffect(() => {
    setDollarValue(amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPercentUnits]);

  return (
    <Table.Tr>
      <Table.Td pb={mb}>
        <Text fw={500} fz={"lg"}>
          {label}
        </Text>
      </Table.Td>
      <Table.Td pb={mb} colSpan={2}>
        {isPercentUnits ? (
          <Group justify="space-between">
            <Group w={"50%"} justify="center">
              <Select
                w={104}
                withCheckIcon={false}
                allowDeselect={false}
                size="lg"
                data={data}
                value={value?.toString()}
                onChange={(value) => {
                  onChange(Number(value));
                }}
                styles={{
                  wrapper: {},
                  input: {
                    fontWeight: 400,
                    textAlign: "right",
                    fontFamily: inputFontFamily,
                  },
                  dropdown: {
                    fontFamily: inputFontFamily,
                  },
                }}
              />
            </Group>
            <Group justify="flex-start" w={"45%"}>
              <Text ta={"left"} fz={"lg"} ff={inputFontFamily}>
                {amount > 0 && formatDollar(amount).replace(".00", "")}
              </Text>
            </Group>
          </Group>
        ) : (
          <Group justify="space-between">
            <Group pl={10} justify="center" w={"calc(50% - 23px)"}>
              <Text ta={"right"} fz={"lg"} w={50} ff={inputFontFamily}>
                {Math.round(value * 100)
                  .toFixed(2)
                  .replace(".00", "")}
                %
              </Text>
            </Group>

            <BigDollarInput
              value={dollarValue}
              w={"50%"}
              size={"lg"}
              onChange={(value) => {
                const v = Number(value);
                setDollarValue(v);
                onChange(Number((v / targetPurchasePrice).toFixed(2)));
              }}
              fw={400}
            />
          </Group>
        )}
      </Table.Td>
    </Table.Tr>
  );
};

const round = (num: number) => Math.round(num * 20) / 20;
