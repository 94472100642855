import { getShortId } from "@/utils/id";
import { Atom, Getter, PrimitiveAtom, atom } from "jotai";
import { focusAtom } from "jotai-optics";
import { splitAtom } from "jotai/utils";
import { createStorableDollarMoleculeFromEpochs } from "./epochMolecule";
import { modelAtom } from "./model";
import {
  CellTypes,
  DollarCell,
  Itemization,
  ItemizationLineItem,
} from "./types";

type ItemizationArgs = {
  id: string;
  consolidatedMolecule: Atom<PrimitiveAtom<CellTypes>[]>;
  lineItemToMolecule: (
    lineItem: ItemizationLineItem,
  ) => Atom<PrimitiveAtom<CellTypes>[]>;
  lineItemColumnToCellAtom: (
    columnCells: PrimitiveAtom<CellTypes>[],
    index: number,
  ) => Atom<CellTypes>;
};

export const createItemizationAtom = (
  arg: ItemizationArgs | ((get: Getter) => ItemizationArgs),
) => {
  const createdLineItemIdsAtom = atom<string[]>([] as string[]);

  return atom((get) => {
    const {
      id,
      consolidatedMolecule,
      lineItemToMolecule,
      lineItemColumnToCellAtom,
    } = typeof arg === "function" ? arg(get) : arg;

    const itemizationFocusAtom = focusAtom(modelAtom, (optic) =>
      optic
        .prop("itemizations")
        .prop(id)
        .valueOr({
          id,
          active: false,
          open: true,
          lineItems: [],
        } as Itemization),
    );

    const activeAtom = focusAtom(itemizationFocusAtom, (optic) =>
      optic.prop("active"),
    );

    const openAtom = focusAtom(itemizationFocusAtom, (optic) =>
      optic.prop("open"),
    );

    const lineItemsSplitAtom = splitAtom(
      focusAtom(itemizationFocusAtom, (optic) => optic.prop("lineItems")),
    );

    const lineItems = get(lineItemsSplitAtom).map((lineItemAtom) =>
      get(lineItemAtom),
    );

    const createLineItemAtom = atom(
      null,
      (_get, set, lineItem: Omit<ItemizationLineItem, "id">) => {
        const newLineItem = {
          id: getShortId(),
          ...lineItem,
        };

        set(lineItemsSplitAtom, {
          type: "insert",
          value: newLineItem,
        });

        set(createdLineItemIdsAtom, (prev) => [...prev, newLineItem.id]);
      },
    );

    const deleteLineItemAtom = atom(null, (_get, set, id: string) => {
      const atom = get(lineItemsSplitAtom).find((atom) => get(atom).id === id);

      if (get(activeAtom) && get(lineItemsSplitAtom).length === 1) {
        set(activeAtom, false);
        set(openAtom, true);
      }

      if (!atom) return;
      set(lineItemsSplitAtom, {
        type: "remove",
        atom,
      });
    });

    const renameLineItemAtom = atom(
      null,
      (_get, set, { id, label }: ItemizationLineItem) => {
        const atom = get(lineItemsSplitAtom).find(
          (atom) => get(atom).id === id,
        );
        if (!atom) return;
        set(atom, {
          ...get(atom),
          label,
        });
      },
    );

    const lineItemsMolecules = get(lineItemsSplitAtom).map((lineItemAtom) =>
      lineItemToMolecule(get(lineItemAtom)),
    );

    const aggregatedColumnsMolecule = atom((get) => {
      const rows = lineItemsMolecules;
      const items = get(consolidatedMolecule);
      return items.map((_, columnIndex) => {
        const columnCells = rows.map((row) => get(row)[columnIndex]);
        return lineItemColumnToCellAtom(columnCells, columnIndex);
      });
    });

    const summarizedMolecule = !get(activeAtom)
      ? consolidatedMolecule
      : aggregatedColumnsMolecule;

    return {
      itemizationFocusAtom,
      activeAtom,
      openAtom,
      lineItems,
      createLineItemAtom,
      deleteLineItemAtom,
      renameLineItemAtom,
      lineItemsMolecules,
      summarizedMolecule,
      consolidatedMolecule,
      aggregatedColumnsMolecule,
      createdLineItemIdsAtom,
    };
  });
};

export const createAggregatedDollarItemizationAtom = (
  id: string,
  options?: { isNegative: boolean },
) => {
  return createItemizationAtom({
    id,
    consolidatedMolecule: createStorableDollarMoleculeFromEpochs(
      `itemization|${id}|consolidated`,
      options,
    ),
    lineItemToMolecule: (lineItem) =>
      createStorableDollarMoleculeFromEpochs(
        `itemization|${id}|lineItem|${lineItem.id}`,
        { isNegative: options?.isNegative || false },
      ),
    lineItemColumnToCellAtom: (cells) =>
      atom((get) => {
        const values = cells.map((cell) => Number(get(cell).value));
        const sum = values.reduce((acc, value) => acc + value, 0);
        return {
          type: "dollar",
          value: sum || 0,
          isNegative: false,
          isLocked: true,
          ...options,
        } as DollarCell;
      }),
  });
};

export const getSummaryValueAtIndex = (
  itemization: ReturnType<typeof createItemizationAtom>,
  index: number,
  get: Getter,
) => {
  return get(get(get(itemization).summarizedMolecule)[index]).value;
};
