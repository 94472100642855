import { dealDocumentAtom } from "@/atoms/deal";
import { useRemoveDeal } from "@/hooks/useDeals";
import { Route } from "@/paths";
import { Button, Group, Stack, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useAtomValue } from "jotai";
import { BiTrash } from "react-icons/bi";
import { generatePath, useNavigate } from "react-router-dom";
import { CreatedAtDate } from "./CreatedAtDate";

export const BottomActions = () => {
  const navigate = useNavigate();
  const [deal] = useAtomValue(dealDocumentAtom);
  const companyName = deal?.companyName || "Unlabeled";
  const [removeDeal, loading] = useRemoveDeal();

  const openDeleteModal = async () => {
    await modals.openConfirmModal({
      title: "Delete this deal",

      children: (
        <Text size="md">
          Are you sure you want to delete the <strong>"{companyName}"</strong>{" "}
          deal? This action is destructive and can't be undone.
        </Text>
      ),
      labels: {
        confirm: `Delete this deal`,
        cancel: "Cancel, keep deal",
      },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        await removeDeal(deal!.id);
        navigate(generatePath(Route.DealTracker));
      },
    });
  };

  return (
    <Stack>
      <Stack gap={"xs"}>
        <Text size="sm" fw={600}>
          Created On
        </Text>
        <CreatedAtDate />
      </Stack>
      <Group gap={"xs"} justify="space-between">
        <Button
          loading={loading}
          variant="light"
          color="red"
          leftSection={<BiTrash />}
          fullWidth
          onClick={openDeleteModal}
        >
          Delete Deal
        </Button>
      </Group>
    </Stack>
  );
};
