import { agGridRefAtom } from "@/atoms/dealTracker/agGrid";
import {
  activeCategoryPaletteAtom,
  allCategoriesAtom,
  categoryCountAtom,
  filteredCategoryIdAtom,
  getPaletteFromId,
} from "@/atoms/dealTracker/category";
import { dealTrackerDocumentAtom } from "@/atoms/dealTracker/dealTracker";
import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import {
  ActionIcon,
  Badge,
  Group,
  Popover,
  SegmentedControl,
  Stack,
} from "@mantine/core";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { BiCog } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa";
import { EditCategoryList } from "./EditCategoryList";

export const Title = () => {
  const [opened, setOpened] = useState(false);
  return (
    <LayoutWorkstation.Sidebar.Title
      rightSection={
        <Popover
          width={240}
          position="bottom"
          withArrow
          shadow="md"
          opened={opened}
          onChange={setOpened}
        >
          <Popover.Target>
            <ActionIcon
              size={28}
              color="gray"
              variant="light"
              onClick={() => setOpened(true)}
            >
              <BiCog size={18} />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown>
            <EditCategoryList onUpdateComplete={() => setOpened(false)} />
          </Popover.Dropdown>
        </Popover>
      }
    >
      Status
    </LayoutWorkstation.Sidebar.Title>
  );
};

export const CategoryList = () => {
  const [dealTracker] = useAtomValue(dealTrackerDocumentAtom);
  const categoryCount = useAtomValue(categoryCountAtom);
  const gridRef = useAtomValue(agGridRefAtom);
  const activeCategoryPalette = useAtomValue(activeCategoryPaletteAtom);
  const categories = useAtomValue(allCategoriesAtom);
  const filteredCategoryId = useAtomValue(filteredCategoryIdAtom);

  if (!dealTracker) return null;

  return (
    <Stack>
      <Title />
      <SegmentedControl
        autoContrast
        color={activeCategoryPalette.backgroundColor}
        size="md"
        m={0}
        p={0}
        orientation="vertical"
        fullWidth
        withItemsBorders={false}
        onChange={(val) => {
          gridRef!
            .current!.api.setColumnFilterModel(
              "categoryId",
              val === "all"
                ? null
                : {
                    filterType: "text",
                    type: "equals",
                    filter: val,
                  },
            )
            .then(() => {
              gridRef!.current!.api.onFilterChanged();
            });
        }}
        data={categories.map((category) => {
          return {
            ...category,
            value: category.id,
            label: (
              <Group justify="space-between">
                <div>{category.label}</div>
                <Group gap={0}>
                  <Badge
                    fz={12}
                    size="md"
                    variant="filled"
                    miw={36}
                    color="gray.6"
                    style={{
                      color: getPaletteFromId(category.paletteId).fontColor,
                      backgroundColor: getPaletteFromId(category.paletteId)
                        .backgroundColor,
                    }}
                  >
                    {categoryCount[category.id] || 0}
                  </Badge>{" "}
                  {filteredCategoryId === category.id && (
                    <FaArrowRight
                      size={12}
                      color={getPaletteFromId(category.paletteId).fontColor}
                    />
                  )}
                </Group>
              </Group>
            ),
          };
        })}
      />
    </Stack>
  );
};
