import { LabelExplainer } from "@/components/LabelExplainer/LabelExplainer";
import { CellTypes } from "@/financeModels/owasco/types";
import { ZIndexOrder } from "@/theme";
import {
  ActionIcon,
  Box,
  BoxComponentProps,
  //Collapse,
  Group,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { Atom, PrimitiveAtom } from "jotai";
import { CSSProperties, ReactNode } from "react";
import { BiSolidPlusCircle } from "react-icons/bi";
import { LuSigma } from "react-icons/lu";
import { MdOutlineClose } from "react-icons/md";
import { CellRow } from "./CellRow";

const pillColor = "var(--mantine-color-brand-6)";
export const lineSize = 4;
export const lineColor = "var(--mantine-color-brand-6)";

const Collapse = ({
  children,
  in: inProp,
  ...props
}: BoxComponentProps & {
  children: ReactNode;
  in: boolean;
}) => {
  return <Box {...props}>{inProp ? children : null}</Box>;
};

export const Waterfall = ({
  children,
  style = {},
}: {
  children: ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <Group gap={0} pos="relative">
      <div
        style={{
          width: lineSize,
          //height: "100px",
          background: lineColor,
          position: "sticky",
          left: 0,
          alignSelf: "stretch",
          zIndex: ZIndexOrder.CellGridWaterfallLine,
          boxShadow: "-20px 20px 0px 20px var(--mantine-color-gray-3)",
        }}
      ></div>
      <Stack
        gap={0}
        style={{
          position: "relative",
          left: -lineSize,
          transform: `translate(${lineSize}px, 0px)`,
          ...style,
        }}
      >
        {children}
      </Stack>
    </Group>
  );
};

Waterfall.Offset = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        transform: "translate(20px, 0px)",
      }}
    >
      {children}
    </div>
  );
};

Waterfall.Pill = ({
  children,
  style = {},
}: {
  children: ReactNode;
  style?: CSSProperties;
}) => {
  return (
    <Group
      bg={pillColor}
      gap={6}
      px={6}
      align="center"
      justify="center"
      style={{
        borderRadius: 3,
        lineHeight: 1.6,
        height: 22,
        fontWeight: 600,
        ...style,
      }}
      c={"white"}
      wrap="nowrap"
    >
      {children}
    </Group>
  );
};

Waterfall.Formula = ({
  children,
  explanation,
}: {
  children: ReactNode;
  explanation: ReactNode;
}) => {
  return (
    <LabelExplainer
      explanation={explanation}
      label={
        <Waterfall.Pill
          style={{
            letterSpacing: 0.2,
            ...(children === "Line Of Credit (Working Capital)"
              ? { fontSize: 12, lineHeight: 1 }
              : {}),
          }}
        >
          <LuSigma size={14} />
          {children}
        </Waterfall.Pill>
      }
    />
  );
};

Waterfall.Collapse = ({
  children,
  label,
  tabY,
  molecule,
  id,
  explanation,
}: {
  children: ReactNode;
  label: ReactNode;
  tabY: number;
  molecule: Atom<PrimitiveAtom<CellTypes>[]> | Atom<Atom<CellTypes>[]>;
  id?: string;
  explanation?: ReactNode;
}) => {
  // if title is a string, use it as the id, otherwise use the id prop
  const storageId = id || typeof label === "string" ? label : id;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useLocalStorage({
    key: "waterfall-collapse-" + storageId + "-" + tabY,
    defaultValue: false,
  });

  return (
    <>
      <Collapse in={open}>
        <Waterfall.Offset>
          <Group gap={0} align="center" mt={4}>
            <div
              style={{
                position: "sticky",
                left: 0,
                transform: `translate(-9px, 0)`,
                display: "flex",
              }}
            >
              <Group gap={"xs"}>
                <Tooltip label="Collapse Calculation">
                  <ActionIcon
                    color="red"
                    onMouseUp={() => setOpen((o) => !o)}
                    p={0}
                    m={0}
                    size={"sm"}
                  >
                    <MdOutlineClose size={18} />
                  </ActionIcon>
                </Tooltip>
                <Text size="sm" c="gray">
                  Expanded calculation for "{label}"
                </Text>
              </Group>
            </div>
          </Group>
          <Waterfall
            style={{
              padding: "10px 0",
            }}
          >
            {children}
          </Waterfall>
        </Waterfall.Offset>

        <CellRow
          bold
          tabY={tabY}
          molecule={molecule}
          label={
            <Waterfall.Formula explanation={explanation}>
              {label}
            </Waterfall.Formula>
          }
        />
      </Collapse>

      <Collapse in={!open}>
        <CellRow
          bold
          tabY={tabY}
          molecule={molecule}
          label={
            <Group gap={4} wrap="nowrap">
              <Tooltip label="Expand Calculation" withArrow>
                <ActionIcon
                  variant="transparent"
                  color="accent.4"
                  onMouseUp={() => setOpen((o) => !o)}
                >
                  <BiSolidPlusCircle size={24} />
                </ActionIcon>
              </Tooltip>
              <Waterfall.Formula explanation={explanation}>
                {label}
              </Waterfall.Formula>
            </Group>
          }
        />
      </Collapse>
    </>
  );
};
