import { monospaceFontFamily } from "@/theme";
import {
  Box,
  BoxComponentProps,
  Group,
  Input,
  Paper,
  Stack,
} from "@mantine/core";
import { ReactNode } from "react";

export const ComputedResult = ({
  label,
  value,
  variant = "light",
  ...props
}: BoxComponentProps & {
  label: ReactNode;
  value: ReactNode;
  variant?: "light" | "default";
}) => {
  if (variant === "default")
    return (
      <Group my="sm" justify="space-between" {...props}>
        <Input.Label component={"div"} size="sm" m={0} mb={0}>
          {label}
        </Input.Label>
        <Box w={120}>
          <Input.Label component={"div"} size="sm" m={0} mb={0}>
            {value}
          </Input.Label>
        </Box>
      </Group>
    );

  return (
    <ComputedResultContainer {...props}>
      <ComputedResultLine label={label} value={value} />
    </ComputedResultContainer>
  );
};

export const ComputedResultContainer = ({
  children,
  ...props
}: BoxComponentProps & { children: ReactNode }) => {
  return (
    <Paper mt={"xs"} bg={"gray.1"} px="sm" py="sm" withBorder {...props}>
      <Stack gap="xs">{children}</Stack>
    </Paper>
  );
};

export const ComputedResultLine = ({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) => {
  return (
    <Group justify="space-between">
      <Input.Label component={"div"} size="sm" m={0} mb={0}>
        {label}
      </Input.Label>
      <Box>
        <Input.Label
          component={"div"}
          size="sm"
          m={0}
          p={0}
          mb={0}
          ff={monospaceFontFamily}
        >
          {value}
        </Input.Label>
      </Box>
    </Group>
  );
};
