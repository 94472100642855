import { agGridRefAtom } from "@/atoms/dealTracker/agGrid";
import { analytics } from "@/firebase";
import { useCreateDeal } from "@/hooks/useDeals";
import { Button } from "@mantine/core";
import { logEvent } from "firebase/analytics";
import { useAtomValue } from "jotai";
import { BiSolidAddToQueue } from "react-icons/bi";

export const CreateDealButton = () => {
  const [create, loading] = useCreateDeal();
  const gridRef = useAtomValue(agGridRefAtom);

  return (
    <Button
      justify="space-between"
      leftSection={<BiSolidAddToQueue size={24} />}
      rightSection={<BiSolidAddToQueue size={24} opacity={0} />}
      size="md"
      onClick={async () => {
        await create();
        gridRef!.current!.api.applyColumnState({
          state: [{ colId: "categoryId", sort: "asc" }],
          defaultState: { sort: null },
        });
        logEvent(analytics, "create_deal");
      }}
      loading={loading}
    >
      Create Deal
    </Button>
  );
};
