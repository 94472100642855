import { NumberInput, NumberInputProps } from "@mantine/core";
import parseMoney from "parse-money";
import { useEffect, useRef, useState } from "react";

export type BigDollarInputProps = Omit<NumberInputProps, "onChange"> & {
  onChange?: (value: number | null) => void;
  textAlign?: "left" | "center" | "right";
};

export const BigDollarInput = ({
  textAlign,
  ...props
}: BigDollarInputProps) => {
  const ref = useRef<null | HTMLInputElement>(null);
  const [displayValue, setDisplayValue] = useState<string>(
    props.value === undefined ? "" : props.value + "",
  );

  useEffect(() => {
    if (
      displayValue.length > 1 &&
      parseMoney(displayValue)?.amount === props.value
    )
      return;

    if (props.value === 0 && displayValue === "") return;

    setDisplayValue(props.value + "");
  }, [props.value]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.addEventListener("keydown", (event) => {
      // cancel arrow keys
      if (["ArrowUp", "ArrowDown"].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }, [ref]);

  return (
    <NumberInput
      ref={ref}
      placeholder="$"
      thousandSeparator=","
      prefix="$"
      allowNegative={false}
      decimalScale={2}
      hideControls
      size="lg"
      {...props}
      value={displayValue}
      onChange={(value) => {
        if (!props.onChange) return;

        const str = value + "";
        setDisplayValue(str);

        const parsedValue = parseMoney(str)?.amount ?? null;
        props.onChange(parsedValue);
      }}
      styles={{
        ...props.styles,
        input: {
          textAlign: textAlign || "left",
        },
      }}
    />
  );
};
