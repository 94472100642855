import { LayoutWorkstation } from "@/components/LayoutWorkstation/LayoutWorkStation";
import { Header } from "./Main/Header";
import { Main } from "./Main/Main";
import { Sidebar } from "./Sidebar/Sidebar";

export const PageDeal = () => {
  return (
    <LayoutWorkstation>
      <Sidebar />
      <LayoutWorkstation.Main>
        <Header />
        <Main />
      </LayoutWorkstation.Main>
    </LayoutWorkstation>
  );
};
